import { Box } from '@mui/system';
import React from 'react';
import classes from './DescriptionInfo.module.css';
const DescriptionInfo = () => {
  return (
    <Box
      sx={{
        gap: 4,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: {
            xs: 'center',
            md: 'left',
          },
        }}
      >
        <span className={classes.titleBlue}>Hvorfor&nbsp;</span>
        <span className={classes.titleGreen}>Lunsj På Døra</span>
        <span className={classes.titleBlue}>?</span>
      </Box>
      <Box ml={3}>
        <ul style={{ listStyleType: 'disc' }} className={classes.points}>
          <li>
            Dette er en enkel måte å “håndtere lunsjen” på arbeidsplassen på. Vi
            leverer rett til pulten din hver dag.
          </li>
          <li>
            Digitalkantine betyr at du personlig styrer alt fra «Lunsj På Døra»
            Appen. Ingen administrasjon.
          </li>
          <li>
            Vi er stolte av at denne tilnærmingen virkelig bidrar til å redusere
            matsvinn.
          </li>
          <li>
            Maten tilberedes med kjærlighet på vårt cateringkjøkken, som har en
            30-år lang historie med å levere mat til næringslivet.
          </li>
          <li>Velg mellom ordinære porsjoner, stor, vegetar eller halal.</li>
          <li>
            Vi streber etter å tilby spennende smaker som er både sunne og
            tilberedt med omtanke fra bunnen av
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default DescriptionInfo;
