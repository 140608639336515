import React from 'react';
import HTMLReactParser from 'html-react-parser';

function Process(props) {
  let content = {
    English: {
      _section1Head: 'Looking for<br/>a Good Lunch Near You?',
      _section1Para:
        "Explore our range of gourmet office lunches in and around Oslo <a href='#loc'>(view locations)</a>, prepared with love, packed with safety, and delivered straight to your office with the single tap of a button.",
      _section1Subhead:
        'Stop Googling and Register for Lunsj På Døra in 3 easy steps',
      _section1Process1Head: 'Register',
      _section1Process2Head: 'Take Control',
      _section1Process3Head: 'Enjoy Lunch',
      _section1Process1Subhead:
        'Fill out the registration form & our team will help sign your business up in a couple of quick minutes.',
      _section1Process2Subhead:
        'Take control of your dashboard where you can add employees. Employees can then download the app and easily view, cancel, or change their meals.',
      _section1Process3Subhead: 'Happiness delivered to your desk!',
    },
    Norwegian: {
      _section1Head: 'Ser du etter<br/>en god lunsj nær deg?',
      _section1Para:
        "Sjekk ut rekkevidden av gourmet kontorlunsjer i og rundt Oslo-området <a href='#loc'>(se leveringssteder)</a>, tilberedt med kjærlighet, pakket med omtanke og nøyaktighet, og levert rett til din arbeidsplass med et enkelt tastetrykk",
      _section1Subhead:
        'Stopp å Google og registrer deg for Lunsj På Døra i 3 enkle steg',
      _section1Process1Head: 'Registrer',
      _section1Process2Head: 'Ta kontroll',
      _section1Process3Head: 'Nyt lunsjen',
      _section1Process1Subhead:
        'Fyll ut registreringsskjemaet og vårt team vil hjelpe deg med å få sjekket inn bedriften din i løpet av få minutter',
      _section1Process2Subhead:
        'Ta kontroll over dashboardet hvor du kan legge til ansatte. Ansatte kan så laste ned appen og på en enkel måte se, endre eller endre sine måltider.',
      _section1Process3Subhead: 'Trivsel levert rett på pulten din!',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <section id='tabs' className='tabs text-center bg-parallax pb-90'>
      <div className='container'>
        <div className='row clearfix'>
          <div className='col-xs-12 col-sm-12 col-md-8 col-md-offset-2'>
            <div className='heading heading-1 mb-50 text--center'>
              <h2 className='heading--title mb-0 mt-0'>
                {HTMLReactParser(content._section1Head)}
              </h2>

              <div className='divider--shape-4'></div>
              <p className='heading--desc'>
                {HTMLReactParser(content._section1Para)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-8 col-md-offset-2'>
            <div className='heading heading-1 mb-50 text--center'>
              <p className='heading--subtitle' style={{ fontSize: 20 }}>
                {content._section1Subhead}
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-10 col-md-offset-1'>
            <div className='dishes-wrapper text--center'>
              <div className='col-xs-12 col-sm-12 col-md-4 pl-30 pr-30'>
                <img
                  src='assets/icons/personal.png'
                  width='70px'
                  style={{
                    boxShadow: '0px 30px 40px #dddddd',
                    marginBottom: 40,
                  }}
                />
                <h3>{content._section1Process1Head}</h3>

                <p>{content._section1Process1Subhead}</p>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-4 pl-30 pr-30'>
                <div className='divider--shape-left-gold'></div>

                <img
                  src='assets/icons/settings.png'
                  width='70px'
                  style={{
                    boxShadow: '0px 30px 40px #dddddd',
                    marginBottom: 40,
                  }}
                />
                <h3>{content._section1Process2Head}</h3>

                <p>{content._section1Process2Subhead}</p>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-4 pl-30 pr-30'>
                <div className='divider--shape-left-gold'></div>

                <img
                  src='assets/icons/lunch-box.png'
                  width='70px;'
                  style={{
                    boxShadow: '0px 30px 40px #dddddd',
                    marginBottom: 40,
                  }}
                />
                <h3>{content._section1Process3Head}</h3>

                <p>{content._section1Process3Subhead}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
