import React from 'react';
import { Link } from 'react-router-dom';

import ReactGA from "react-ga4";

function TopBar(props) {

  const iosClick = () => {
 
    ReactGA.event({ category: 'Home', action: 'Click', label: 'Apple Store' });
    window.open('https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017', '_blank');

};

const andriodClick = () => {
 
    ReactGA.event({ category: 'Home', action: 'Click', label: 'Google Play Store' });

    window.open('https://play.google.com/store/apps/details?id=no.digiberry.lunchapp&gl=NO', '_blank');

};

const contactClick = () => {
 
  ReactGA.event({ category: 'Home', action: 'Click', label: 'ContactUs' }); 

};

const testimonialsClick = () => {
 
  ReactGA.event({ category: 'Home', action: 'Click', label: 'Testimonials' }); 

};



  let content = {
    English: {
      _signIn: 'Sign In',
      _menuItem1: 'Explore Our Menu',
      _menuItem2: 'The Lunsj På Døra Experience',
      _menuItem3: 'Why Us',
      _menuItem4: 'Testimonials',
      _menuItem5: 'Contact Us',
    },
    Norwegian: {
      _signIn: 'Logg inn',
      _menuItem1: 'Sjekk vår meny',
      _menuItem2: 'Lunsj På Døra Opplevelsen',
      _menuItem3: 'Hvorfor oss',
      _menuItem4: 'Testimonials',
      _menuItem5: 'Kontakt oss',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <header
      id='navbar-spy'
      className='header header-fixed header-transparent-topbar'
    >
      <div id='top-bar' className='top-bar'>
        <div className='container-fluid'>
          <div>
            <div className='row text-center-xs'>
              <div className='col-xs-12 col-sm-6 col-md-6 top--contact'>
                <ul className='list-inline mb-0'>
                  <li>
                    <i className='fa fa-envelope'></i>
                    <span>
                      <a href='mailto:lunsjpadora@conluo.no'>
                        lunsjpadora@conluo.no
                      </a>
                    </span>
                  </li>
                  <li>
                    <i className='fa fa-phone'></i>{' '}
                    <span>
                      <a href='tel:40001315'>40-00-13-15</a>
                    </span>
                  </li>
                  <li>
                    <select
                      className='tripadvisor'
                      value={props.language}
                      style={{
                        background: '#0c0909',
                        padding: '0.3rem',
                        border: 'none',
                        fontWeight: 'bold',
                      }}
                      onChange={(e) => props.handleSetLanguage(e.target.value)}
                    >
                      <option value='English'>EN</option>
                      <option value='Norwegian'>NO</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6 top--social text-right hidden-xs'>
                <a
                  className='instagram'
                  href='https://signin.lunsjpadora.no'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {content._signIn}
                </a>
                <a
                  id='langBtn'
                  data-value=''
                  className='tripadvisor'
                  href='#'
                ></a>
                <a
                  href="#" onClick={andriodClick} 
                  rel='noreferrer noopener'
                >
                  <img
                    src='assets/images/banners/gp.webp'
                    alt='image'
                    style={{ height: 30 }}
                  />
                </a>
                <a
                 href="#" onClick={iosClick} 
                  rel='noreferrer noopener'
                >
                  <img
                    src='assets/images/banners/as.webp'
                    alt='image'
                    style={{ height: 30 }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        id='primary-menu'
        className='navbar navbar-fixed-top affix'
        style={{ top: -120 }}
      >
        <div className='container-fluid'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#navbar-collapse-1'
              aria-expanded='false'
            >
              <span className='sr-only'>Toggle navigation</span>
              <span className='icon-bar'></span>
              <span className='icon-bar'></span>
              <span className='icon-bar'></span>
            </button>

            <Link className='logo' to='/'>
              <img
                className='logo-light'
                style={{ paddingLeft: 10 }}
                src='assets/images/logo/logo-light.png'
                alt='Lunsj På Døra  Logo'
              />
              <img
                className='logo-dark'
                style={{ paddingLeft: 10 }}
                src='assets/images/logo/logo-dark.png'
                alt='Lunsj På Døra  Logo'
              />
            </Link>
          </div>

          <div
            className='collapse navbar-collapse pull-right'
            id='navbar-collapse-1'
          >
            <ul className='nav navbar-nav nav-pos-right navbar-left'>
              <li className='menu-item'>
                <Link to='/#menu7' className='menu-item'>
                  {content._menuItem1}
                </Link>
              </li>
              <li className='menu-item'>
                <Link to='/#tabs' className='menu-item'>
                  {content._menuItem2}
                </Link>
              </li>

              <li className='menu-item'>
                <Link to='/#tabs1' className='menu-item'>
                  {content._menuItem3}
                </Link>
              </li>

              <li className='menu-item'>
                <Link className='menu-item' onClick={ testimonialsClick} to='/#testimonial4'>
                  {content._menuItem4}
                </Link>
              </li>

              <li className='menu-item'>
                <a
                  href='#'
                  className='menu-item'
                  data-toggle='modal'
                  data-target='#contactUsModule'
                  onClick={contactClick}
                >
                  {content._menuItem5}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default TopBar;
