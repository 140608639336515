import React from "react";
import HTMLReactParser from "html-react-parser";
import {  Link } from "react-router-dom";



function Menu(props) {

    let content = {
        English: {
            _section3Head1: "Explore Our Exciting",
            _section3Head2: "MENUS",
            _section3Para: "Lunsj På Døra is a proud product of our company, <a target='_blank' href='https://conluo.no'>Conluo</a>. Our food partners at <a target='_blank' href='https://radeselskapsmat.no'>Råde Selskapsmat</a> AS bring the food to your desk with a lot of love and passion. With a legacy of over 20 years, they are now the leading catering company in Østfold that also delivers office lunch in Oslo. They have several canteens and a bakery (Blings og Boller), that conjures up tempting yeast cakes, sourdough bread, and so much more!",
            _section3Menu1: "MEATY GOODNESS",
            _section3Menu2: "VEGETARIAN FRESHNESS",
            _section3Menu3: "DAILY FARE",
            _section3Menu1D: "Food solutions for the workplace just got more exciting with our innovative menus. Explore our range of gourmet delicacies with the goodness of home and the flavors of luxury. Our succulent meat is freshly and responsibly sourced and available in both halal and non-halal options.",
            _section3Menu2D: "Lunch at work would be incomplete without our selection of fresh vegetarian preparations. Sourced from local farms and flavored with wholesome and mouth-watering ingredients, explore our range of vegetarian food solutions for the workplace.",
            _section3Menu3D: "Our chefs at Lunsj på døra have a selection of comfort offerings and popular favorites on the menu. These mouthwatering dishes are always available for you.",
            _section3Explore: "EXPLORE MENU"
        },
        Norwegian: {
            _section3Head1: "Utforsk våre spennende",
            _section3Head2: "MENYER",
            _section3Para: "Lunsj på døra er et produkt vi er et produkt vi i <a target='_blank' href='https://conluo.no'>Conluo</a> er stolte av. Vårt renomerte datterselskap <a target='_blank'  href='https://radeselskapsmat.no'>Råde Selskapsmat</a> AS bringer maten til din arbeidsplass med en god dose kjærlighet og omtanke. Med en erfaring på mer enn 20 år, så er de nå det ledende catering-selskapet i Østfold. Og som nå altså også leverer lunsj til hele Oslo-området med mer. De driver flere kantiner og et bakeri (Blings og Boller) som tryller frem lekre kaker, surdeigsbrød og så mye mye mer!",
            _section3Menu1: "Kjøttfylt og mørt",
            _section3Menu2: "Frisk vegetar",
            _section3Menu3: "Dagens tilbud",
            _section3Menu1D: "Mat på arbeidsplassen ble akkurat mer spennende med våre innovative menyer. Sjekk vårt utvalg av hjemmelagede gourmetdelikatesser med en smak av luksus. Vårt saftige kjøtt er ferskt, bærekraftig håndtert og tilgjengelig i både halal og ikke-halal alternativer.",
            _section3Menu2D: "Lunsj på jobben ville vært ufullstendig uten vårt utvalg av ferske vegetar-retter. Hentet fra lokale gårder og smaksatt med sunne og appetittvekkende ingredienser. Sjekk ut vårt utvalg av vegetariske matløsninger for arbeidsplassen",
            _section3Menu3D: "Våre Lunsj-på-døra-kokker har et utvalg av gode tilbud og populære favoritter på menyen. Disse appetittvekkende rettene er alltid tilgjengelig for deg.",
            _section3Explore: "Sjekk menyen"
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <section id="menu7" className="menu menu-7 bg-white text-center">
    <div className="container">
        <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                <div className="heading heading-1 mb-50 text--center">
                    <p className="heading--subtitle"> {content._section3Head1 }</p>
                    <h2 className="heading--title mb-0"> {content._section3Head2 }</h2>
                    <div className="divider--shape-4"></div>
                    <p className="heading--desc">
                        {HTMLReactParser(content._section3Para) }
                    </p>
                </div>
            </div>

        </div>

    </div>

    <div className="container pr-0 pl-0">
        <div className="row">

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="member">
                    <div className="member-img" style={{borderRadius: '50px 0px 0px 50px'}}>
                        <img src="assets/images/menu/2.webp" alt="member" />
                        <div className="member-overlay">
                            <div className="member-hover">
                                <div className="pos-vertical-center">

                                    <div className="member-desc">
                                        <p>
                                            {content._section3Menu1D }
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="member-info">
                        <h5>{content._section3Menu1 }</h5>
                        
                        
                      <Link className="btn btn--primary btn--bordered" to="/MeatyGoodnessMenu">{content._section3Explore }</Link>
                     
   

                    </div>

                </div>

            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="member">
                    <div className="member-img">
                        <img src="assets/images/menu/1.webp" alt="member" />
                        <div className="member-overlay">
                            <div className="member-hover">
                                <div className="pos-vertical-center">

                                    <div className="member-desc">
                                        <p>
                                            {content._section3Menu2D }
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="member-info">
                        <h5>{content._section3Menu2 }</h5>
                      
                        <Link className="btn btn--primary btn--bordered" to="/VegetarianFressnessMenu">{content._section3Explore }</Link>

                    </div>

                </div>

            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="member">
                    <div className="member-img" style={{borderRadius: '0px 50px 50px 0px'}}>
                        <img src="assets/images/menu/3.webp" alt="member" />
                        <div className="member-overlay">
                            <div className="member-hover">
                                <div className="pos-vertical-center">

                                    <div className="member-desc">
                                        <p>
                                            {content._section3Menu3D }
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="member-info">
                        <h5>{content._section3Menu3 }</h5>
                        <Link className="btn btn--primary btn--bordered" to="/DailyFairMenu">{content._section3Explore }</Link>

                    </div>

                </div>

            </div>



        </div>


    </div>

</section>

   
  );
}

export default Menu;
