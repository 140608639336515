import React from 'react';

function MenuDaily(props) {
  let content = {
    English: {
      _menu3D1Head: 'Ham and Cheese Whole Grain Baguette',
      _menu3D1Subhead:
        'House-baked whole grain baguette with classic ham and cheese.',
      _menu3D1Para: '(Allergens: gluten- hvete; laktose- melk)',
      _menu3D2Head: 'Chicken and Feta Salad with House Baked Bread',
      _menu3D2Subhead:
        'Farm fresh salad with chicken, feta cheese and barley served with house-baked bread.',
      _menu3D2Para: '(Allergens: laktose- melk; gluten- bygg, hvete, rug)',
    },
    Norwegian: {
      _menu3D1Head: 'Fullkornsbaguette med skinke og ost',
      _menu3D1Subhead: 'Husbakt fullkornsbaguette med klassisk skinke og ost.',
      _menu3D1Para: '(Allergener: gluten- hvete; laktose- melk)',
      _menu3D2Head: 'Kylling og feta salat med husbakt brød',
      _menu3D2Subhead:
        'Farm fresh salat med kylling, fetaost og bygg servert med husbakt brød.',
      _menu3D2Para: '(Allergener: laktose- melk; gluten- bygg, hvete, teppe)',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <section id='menu1' className='menu menu-1 bg-white'>
      <div className='container'>
        <div className='row'>
          <center>
            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu3D1Head}</h4>

                <p className='dish--desc'>{content._menu3D1Subhead}.</p>
                <p className='color-theme'>{content._menu3D1Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu3D2Head}</h4>

                <p className='dish--desc'>{content._menu3D2Subhead}.</p>
                <p className='color-theme'>{content._menu3D2Para}</p>
              </div>
            </div>
          </center>
        </div>
      </div>
    </section>
  );
}

export default MenuDaily;
