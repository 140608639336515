import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../utils/config';
import CompanyOnboardingDetails from './CompanyOnboardingDetails';
import CompanyOnboardingFinalPage from './CompanyOnboardingFinalPage';
import classes from './CompanyOnboardingPage.module.css';
import CompanyOnboardingPricing from './CompanyOnboardingPricing';
import { priceType } from '../../utils/enumUtils';

ReactGA.send({
  hitType: 'pageview',
  page: '/company-registration',
  title: 'company-registration',
});

const CompanyOnboardingPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [info, setInfo] = useState();
  const handleClose = () => {
    setModalUIState(1);
    setPricingState(1);
    setInfo(null);
    navigate('/', { replace: true });
  };
  const [modalUIState, setModalUIState] = useState(1);

  const [pricingState, setPricingState] = useState(1);

  const updatePricingState = (pricing) => setPricingState(pricing);

  const moveToOnboardingDetailScreen = (info) => {
    setInfo(info);
    setModalUIState(2);
  };

  const handleFormSubmission = async (info2) => {
    const data = { ...info, ...info2 };
    const orgNo = data.orgNumber.replaceAll(' ', '');
    // console.log('data :', data);
    const postData = {
      addressPostalCode: data.pin,
      addressLine: data.address,
      addressCity: data.city,
      selectedPlanAmount:
        pricingState === 1 ? priceType.option1 : priceType.option2,
      desiredEmployeeContribution: data.desiredEmployeeContribution,
      desiredLunchStartDate: data.date,
      orgNumber: orgNo,
      orgName: data.orgName,
      referralCode: data.referenceCode,
      adminName: data.orgAdminName,
      adminphone: data.orgAdminPhone,
      adminEmail: data.orgAdminEmail,
      otp: data.otp,
      canteenId: process.env.REACT_APP_CANTEEN_ID,
    };

    const toastId = toast.loading(`Sending your information...`);
    try {
      const res = await fetch(`${baseurl}/self-onboarding/organizations`, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (res.ok) {
        toast.dismiss(toastId);
        toast.success(t('Information sent successfully'));
        setModalUIState(3);
      } else {
        toast.dismiss(toastId);

        const resErr = await res.json();
        throw new Error(resErr.message);
      }
    } catch (e) {
      console.log(e.message);
      toast.dismiss(toastId);
      toast.error(e.message);
      if (e.message === 'Invalid postal code.') setModalUIState(1);
    }
  };

  const primaryMenu = document.getElementById('primary-menu');
  if (primaryMenu) {
    primaryMenu.style.visibility = 'hidden';
  }

  let modalUI = (
    <CompanyOnboardingPricing
      info={info}
      onClickNext={moveToOnboardingDetailScreen}
      pricing={pricingState}
      updatePricingState={updatePricingState}
    />
  );
  if (modalUIState === 1)
    modalUI = (
      <CompanyOnboardingPricing
        info={info}
        onClickNext={moveToOnboardingDetailScreen}
        pricing={pricingState}
        updatePricingState={updatePricingState}
      />
    );
  else if (modalUIState === 2)
    modalUI = (
      <CompanyOnboardingDetails
        onClickSubmit={handleFormSubmission}
        onClickBack={(data) => {
          console.log('go Back');
          setInfo((prev) => {
            return { ...prev, ...data };
          });
          setModalUIState(1);
        }}
        savedData={info}
      />
    );
  // else if (modalUIState === 3)
  //   modalUI = (
  //     <CompanyOnboardingOTPPage
  //       onClickSubmit={verifyOTP}
  //       onClickBack={(e) => {
  //         setModalUIState(2);
  //       }}
  //     />
  //   );
  else if (modalUIState === 3)
    modalUI = <CompanyOnboardingFinalPage onClickNext={handleClose} />;

  return (
    <>
      <Box bgcolor='#000' className={classes.container}>
        <Box className={classes.videoContainer}>
          <video
            autoPlay
            muted
            loop
            id='myVideo'
            height='1280'
            style={{ marginTop: -100 }}
          >
            <source
              src='assets/images/sliders/slide-bg/video.mp4'
              type='video/mp4'
            />
          </video>
        </Box>
        <Box
          p={4}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'left' },
            zIndex: 2,
            position: 'relative',
          }}
        >
          <img
            src='assets/images/logo/ConLouIconLogo.svg'
            alt='logo'
            className={classes.img}
            onClick={handleClose}
          />
        </Box>
        <Box
          sx={{
            zIndex: 2,
            position: 'relative',
          }}
        >
          {modalUI}
        </Box>
      </Box>
    </>
  );
};

export default CompanyOnboardingPage;
