import { Box, Grid } from '@mui/material';
import React from 'react';
import DescriptionInfo from './DescriptionInfo';

const DescriptionSection = () => {
  return (
    <Box
      mt={9}
      sx={{
        backgroundColor: '#F9FDF4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
        }}
      >
        <img
          src='assets/images/onboarding/description_img.png'
          alt='food'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
        }}
      >
        <DescriptionInfo />
      </Box>
    </Box>
  );
};

export default DescriptionSection;
