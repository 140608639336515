import React from "react";




function MenuVeg(props) {

    let content = {
        English: {
            _menu2D1Head: "Grilled Veg Open Sandwich",
            _menu2D1Subhead: "Fresh House baked Sourdough slathered with barbeque sauce topped with farm grilled veg and crunchy coleslaw.",
            _menu2D1Para: "(Allergens: Gluten- hvete, rug, bygg, Sesam, Soya, Sennep, Egg, selleri)",
            _menu2D2Head: "Spiced Sweet Potato and Chickpeas Salad Bowl",
            _menu2D2Subhead: "Quinoa salad with chickpeas and sweet potato. Served with Cilantro Vinaigrette and house-baked bread.",
            _menu2D2Para: "(Allergens: soya, sulfitt; Gluten- hvete, rug, bygg)",
            _menu2D3Head: "Slow Cooked Indian Dal with Fluffy Naan Bread",
            _menu2D3Subhead: "Slow-cooked lentil stew, lightly spiced, with a coconut milk base, served with fresh sour cream and home-baked fluffy naan bread.",
            _menu2D3Para: "(Allergens: Gluten- hvete, laktose- melk)",
            _menu2D4Head: "Vegetarian Asian Noodle Salad",
            _menu2D4Subhead: "Spicy noodle salad with farm fresh vegetables topped with crunchy cashews.",
            _menu2D4Para: "(Allergens: egg; gluten- hvete, soyanøtter- cashew, bløtdyr )",
            _menu2D5Head: "Baked Sweet Potato with Chives Butter",
            _menu2D5Subhead: "Tomato infused root vegetables stuffed inside tender-baked sweet potato, topped with a luxurious chives butter.",
            _menu2D5Para: "(Allergens: laktose- melk, selleri, sulfitt)",
            _menu2D6Head: "Sweet Potato and Chickpeas Wrap",
            _menu2D6Subhead: "Handmade tortilla topped with fresh vegetables, seasoned sweet potato and chickpeas.",
            _menu2D6Para: "(Allergens: gluten- hvete)",
            _menu2D7Head: "Falafel with Grilled Paprika in Tomato Sauce",
            _menu2D7Subhead: "Grilled paprika and mushrooms slow-cooked in tangy tomato sauce topped with crunchy falafel, on a bed of warm rice.",
            _menu2D7Para: "(Allergens: sulfitt, Selleri, Sesam; gluten- hvete)",
            _menu2D8Head: "Chickpea Patties, Crunchy Salad with Feta & Signature House-Baked Bread",
            _menu2D8Subhead: "Chickpea patties, baked sweet potato and feta served atop fresh salad leaves accompanied with house-baked bread.",
            _menu2D8Para: "(Allergens: gluten- hvete, rug, bygg; laktose- melk, sesam)",
            _menu2D9Head: "Herb-Baked Portobello Mushroom & Crispy Potato Wedges",
            _menu2D9Subhead: "Herb baked portobello mushrooms with fricassee of root vegetables and potato wedges.",
            _menu2D9Para: "(Allergens: selleri; laktose- melk)",
            _menu2D10Head: "Hummus Open Sandwich with House-Baked Sourdough",
            _menu2D10Subhead: "House-baked sourdough topped with creamy hummus and crunchy red onions.",
            _menu2D11Head: "Protein Loaded Veggie Hummus and Avocado Bowl with House-Baked Bread",
            _menu2D11Subhead: "Mushrooms, sugar snaps and pineapples cooked in our signature sweet n sour sauce accompanied by spring rolls made with bean sprouts and mushrooms. Served with warm rice.",
            _menu2D11Para: "(Allergens: Soya, Sulfitt Gluten- hvete, Egg)",
            _menu2D12Head: "Broccoli and Mushroom Salad with House-Baked Bread",
            _menu2D12Subhead: "Creamy Broccoli, Juicy Fried Mushrooms, Raisins, Sunflower and Pumpkin seeds served with farm fresh salad leaves and house-baked bread.",
            _menu2D12Para: "(Allergens: Laktose- melk; Gluten- hvete, rug, bygg)",
            _menu2D13Head: "Vegetarian Lasagna",
            _menu2D13Subhead: "Vegetarian lasagna with juicy squash, eggplant, red lentils and tangy tomato sauce, served with a side of farm-fresh salad.",
            _menu2D13Para: "(Allergens: Gluten- hvete; Laktose- melk, Egg)",
            _menu2D14Head: "House-Baked Cheese Baguette",
            _menu2D14Subhead: "Whole grain baguette, house-baked with cheese.",
            _menu2D14Para: "(Allergens: gluten- hvete; laktose- melk)"
        },
        Norwegian: {
            _menu2D1Head: "Grillet Veg Open Sandwich",
            _menu2D1Subhead: "Fresh House-bakt surdeig slathered med barbeque saus toppet med gården grillet grønnsaker og crunchy coleslaw.",
            _menu2D1Para: "(Allergener: Gluten- hvete, rug, bygg, Sesam, Soya, Sennep, Egg, selleri)",
            _menu2D2Head: "Krydret søtpotet og kikerter salatbolle",
            _menu2D2Subhead: "Quinoasalat med kikerter og søtpotet. Serveres med Cilantro Vinaigrette og husbakt brød.",
            _menu2D2Para: "(Allergener: soya, sulfitt; Gluten- hvete, teppe, bygg)",
            _menu2D3Head: "Saktekokt indisk Dal med luftig Naan-brød",
            _menu2D3Subhead: "Saktekokt linsestuing, lett krydret, med kokosmelkbunn, servert med fersk rømme og hjemmebakt luftig naanbrød.",
            _menu2D3Para: "(Allergener: Gluten- hvete, laktose- melk)",
            _menu2D4Head: "Vegetarisk asiatisk nudelsalat",
            _menu2D4Subhead: "Krydret nudelsalat med ferske grønnsaker på gården toppet med knasende cashewnøtter.",
            _menu2D4Para: "(Allergener: egg; gluten- hvete, soyanøtter- cashew, bløtdyr )",
            _menu2D5Head: "Bakt søtpotet med gressløksmør",
            _menu2D5Subhead: "Tomatinfunderte rotgrønnsaker fylt inni mørbakt søtpotet, toppet med et luksuriøst gressløksmør.",
            _menu2D5Para: "(Allergener: laktose-melk, selleri, sulfitt)",
            _menu2D6Head: "Søtpotet- og kikerterwrap",
            _menu2D6Subhead: "Håndlaget tortilla toppet med ferske grønnsaker, krydret søtpotet og kikerter.",
            _menu2D6Para: "(Allergener: gluten-hvete)",
            _menu2D7Head: "Falafel med grillet paprika i tomatsaus",
            _menu2D7Subhead: "Grillet paprika og sopp saktekokt i syrlig tomatsaus toppet med sprø falafel, på en seng med varm ris.",
            _menu2D7Para: "(Allergener: sulfitt, Selleri, Sesam; gluten-hvete)",
            _menu2D8Head: "Kikertkaker, sprø salat med fetaost og hjemmebakt signaturbrød",
            _menu2D8Subhead: "Kikertkaker, bakt søtpotet og feta servert på toppen av friske salatblader akkompagnert med husbakt brød.",
            _menu2D8Para: "(Allergener: gluten- hvete, rug, bygg; laktose- melk, sesam)",
            _menu2D9Head: "Urtebakt Portobellosopp og sprø potetskiver",
            _menu2D9Subhead: "Urtebakt portobellosopp med frikassé av rotgrønnsaker og potetbåter.",
            _menu2D9Para: "(Allergener: selleri; laktose- melk)",
            _menu2D10Head: "Hummus Open Sandwich med husbakt surdeig",
            _menu2D10Subhead: "Husbakt surdeig toppet med kremet hummus og sprø rødløk.",
            _menu2D11Head: "Proteinfylt grønnsakshummus og avokadobolle med husbakt brød",
            _menu2D11Subhead: "Sopp, sugar snaps og ananas tilberedt i vår signatur sweet n sour saus akkompagnert av vårruller laget med bønnespirer og sopp. Serveres med varm ris.",
            _menu2D11Para: "(Allergener: Soya, Sulfitt; Glutenhvette, Egg)",
            _menu2D12Head: "Brokkoli og soppsalat med husbakt brød",
            _menu2D12Subhead: "Kremet brokkoli, saftig stekt sopp, rosiner, solsikke- og gresskarfrø servert med friske salatblader og husbakt brød.",
            _menu2D12Para: "(Allergener: Laktose- melk; Gluten- hvete, teppe, bygg)",
            _menu2D13Head: "Vegetarisk lasagne",
            _menu2D13Subhead: "Vegetarlasagne med saftig squash, aubergine, røde linser og syrlig tomatsaus, servert med en gårdsfrisk salat.",
            _menu2D13Para: "(Allergener: Gluten- hvete; Laktose- melk, Egg)",
            _menu2D14Head: "Husbakt ostebaguette",
            _menu2D14Subhead: "Fullkornsbaguette, husbakt med ost.",
            _menu2D14Para: "(Allergener: gluten- hvete; laktose- melk)"
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
      
      
   <section id="menu1" className="menu menu-1 bg-white">
   <div className="container">
       <div className="row">
           <center>
               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D1Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D1Subhead}.</p>
                       <p className="color-theme">{content._menu2D1Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D2Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D2Subhead}.</p>
                       <p className="color-theme">{content._menu2D2Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D3Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D3Subhead}.</p>
                       <p className="color-theme">{content._menu2D3Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D4Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D4Subhead}.</p>
                       <p className="color-theme">{content._menu2D4Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D5Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D5Subhead}.</p>
                       <p className="color-theme">{content._menu2D5Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D6Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D6Subhead}.</p>
                       <p className="color-theme">{content._menu2D6Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D7Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D7Subhead}.</p>
                       <p className="color-theme">{content._menu2D7Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D8Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D8Subhead}.</p>
                       <p className="color-theme">{content._menu2D8Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D9Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D9Subhead}.</p>
                       <p className="color-theme">{content._menu2D9Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D10Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D10Subhead}.</p>
                       <p className="color-theme">{content._menu2D10Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D11Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D11Subhead}.</p>
                       <p className="color-theme">{content._menu2D11Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D12Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D12Subhead}.</p>
                       <p className="color-theme">{content._menu2D12Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D13Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D13Subhead}.</p>
                       <p className="color-theme">{content._menu2D13Para}</p>

                   </div>
                  
               </div>

               <div className="col-xs-12 col-sm-12 col-md-12">
                   <div className="dish-panel">
                       <h4>{content._menu2D14Head}</h4>
                      
                       <p className="dish--desc">{content._menu2D14Subhead}.</p>
                       <p className="color-theme">{content._menu2D14Para}</p>

                   </div>
                  
               </div>

               
           </center>
       </div>
      
   </div>
 
</section>

   
  );
}

export default MenuVeg;
