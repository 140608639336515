import { Box } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import ReviewCard from './ReviewCard';
const ReviewCardSlider = ({ reviewPersonInfo }) => {
  return (
    <Carousel autoPlay={false}>
      <ReviewCard {...reviewPersonInfo[0]} />
      <ReviewCard {...reviewPersonInfo[1]} />
      <ReviewCard {...reviewPersonInfo[2]} />
      <ReviewCard {...reviewPersonInfo[3]} />
    </Carousel>
  );
};

export default ReviewCardSlider;
