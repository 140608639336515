import { Box, Paper, Stack } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { t } from 'i18next';
const PricingOption = ({
  title = 'Option 1',
  amount = 99,
  info,
  isSelected = true,
  updateSelection,
  mvaType,
}) => {
  return (
    <Paper
      variant='outlined'
      sx={{
        width: '260px',
        padding: 2,
        color: '#364564',
        borderColor: isSelected ? '#354465' : '#EEEEEE',
        cursor: 'pointer',
      }}
      onClick={updateSelection}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Rubik',
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          {t(title)}
        </Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          {t('Price per meal')}
        </Box>
        <Stack
          sx={{
            color: '#8FB869',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <Stack direction='row' gap='.2rem'>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '19px',
                letterSpacing: '0em',
                textAlign: 'center',
                marginTop: '16px',
              }}
            >
              Kr
            </Box>
            <Box
              sx={{
                fontFamily: 'Rubik',
                fontSize: '48px',
                fontWeight: '400',
                lineHeight: '56px',
                letterSpacing: '0em',
                textAlign: 'center',
              }}
            >
              {amount}
            </Box>
          </Stack>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '13px',
              fontWeight: 500,
              fontFamily: 'Raleway',
            }}
          >
            {t(mvaType)}
          </Box>
        </Stack>
        <Box
          sx={{
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: '300',
            lineHeight: '17px',
            letterSpacing: '0em',
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}
        >
          {info}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Raleway',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '0px',
            textAlign: 'left',
            gap: 1,
          }}
        >
          {isSelected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
          {t('Select')}
        </Box>
      </Box>
    </Paper>
  );
};

export default PricingOption;
