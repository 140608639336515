import { Box, Grid } from '@mui/material';
import React, { forwardRef } from 'react';
import HeaderFormSection from './HeaderFormSection';
import HeaderInfoSection from './HeaderInfoSection';

const HeaderSection = forwardRef(({ changePinButtonVisibility }, ref) => {
  //Test changes
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        gap: 4,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', lg: '60%' },
        }}
      >
        <HeaderInfoSection />
      </Box>
      <Box sx={{ width: { xs: '100%', lg: '40%' }, overFlow: 'hidden' }}>
        <HeaderFormSection
          ref={ref}
          changePinButtonVisibility={changePinButtonVisibility}
        />
      </Box>
    </Box>
  );
});

export default HeaderSection;
