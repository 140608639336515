import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classes from './IconLabelComponentNew.module.css';
const IconLabelComponentNew = ({ infoIcon, infoMessage }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        // minWidht: '150px',
        // minHeight: '150px',
        // maxWidth: '226px',
        // maxHeight: '226px',
        display: 'flex',
        flex: '1 1 0',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: 3,
        padding: '20px',
        background:
          'linear-gradient(179deg, rgba(255, 255, 255, 0.00) 0%, #94BD6E 100%)',
        borderRadius: '20px',
      }}
    >
      <img
        src={infoIcon}
        alt={infoMessage}
        style={{
          width: '72px',
          height: '72px',
        }}
      />
      <Box
        className={classes.textStyle}
        sx={{
          textAlign: 'center',
        }}
      >
        {infoMessage}
      </Box>
    </Box>
  );
};

export default IconLabelComponentNew;
