import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Slider,
  Stack,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DatePickerTwoDaysFutureComponent } from '../../components/DatePickerComponent';
import {
  dateFormatForServer,
  dateFormatToDisplay,
  getNextBusinessDay,
} from '../../utils/dateUtils';
import { buttonStyle } from '../../utils/sxStyles';
import IconLabelComponent from './IconLabelComponent';
import PricingOption from './PricingOption';
import { priceType } from '../../utils/enumUtils';
import { useEffect } from 'react';

const textFieldFormSx = {
  minWidth: '300px',
  backgroundColor: '#fff',
  borderRadius: '1px',
};
const headerSx = {
  color: '#8FB869',
  fontFamily: 'Raleway',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '23px',
  letterSpacing: '0em',
  textAlign: 'center',
};

const smallerContainerWidth = {
  xs: '90%',
  sm: '80%',
  md: '70%',
};

const schema = yup.object({
  pin: yup.string().trim().max(50).required('PIN is Required'),
  city: yup.string().trim().required('City is Required'),
  // userMealPrice: yup
  //   .number()
  //   .min(0, 'User Payable Price should be more than 0')
  //   .max(100, `User Payable Price should be less than ${totalMealPrice}`)
  //   .required('User Payable Price is Required'),
  address: yup.string().trim().required('Address is Required'),
});

const marksType1 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: priceType.option1,
    label: priceType.option1.toString(),
  },
];

const marksType2 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: priceType.option2,
    label: priceType.option2.toString(),
  },
];

const CompanyOnboardingPricing = ({
  info,
  onClickNext,
  pricing,
  updatePricingState,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: info,
  });

  const { t } = useTranslation();
  const { nextBusinessDaySelection } = getNextBusinessDay();
  const [selectedDate, setSelectedDate] = useState(
    info?.date
      ? info?.date
      : moment(nextBusinessDaySelection, dateFormatToDisplay).format(
          dateFormatForServer
        )
  );
  const [sliderValue, setSliderValue] = useState(
    info?.desiredEmployeeContribution ?? 50
  );

  useEffect(() => {
    if (pricing === 2) {
      setSliderValue(priceType.option2);
    }
  }, [pricing]);

  const onSubmitCompanyOnBoardingPricing = async (data) => {
    console.log('onSubmitCompanyOnBoardingPricing', selectedDate);
    data.date = selectedDate;
    console.log(data.date);
    data.desiredEmployeeContribution = sliderValue;
    console.log(data);
    onClickNext(data);
  };

  // console.log('saved date', dayjs(info?.date), selectedDate);

  return (
    <Box
      sx={{
        width: { xs: '90%', lg: '60%' },
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        flexDirection: 'column',
        mb: 4,
      }}
    >
      <Box
        sx={{
          fontFamily: 'Cormorant Garamond',
          fontSize: { xs: '28px', md: '45px' },
          fontWeight: { sx: 700, md: 600 },
          lineHeight: { sx: '33px', md: '54px' },
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#fff',
          mt: { sx: 4, md: 8 },
        }}
      >
        {t('No canteen at work?')}
      </Box>

      <Box
        sx={{
          fontFamily: 'Rubik',
          fontSize: { xs: '24px', md: '32px' },
          fontWeight: 400,
          lineHeight: { sx: '28px', md: '38px' },
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#C0F98C',
          mt: 4,
        }}
      >
        {t('We have solved this for you - Digital Canteen only for NOK 99')}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
          mt: 4,
        }}
      >
        <IconLabelComponent
          infoIcon='assets/images/onboarding/order-food.png'
          infoMessage='Cancel and book with a single click in the app'
        />
        <IconLabelComponent
          infoIcon='assets/images/onboarding/cooking.png'
          infoMessage='Food made with fresh ingredients'
        />
        <IconLabelComponent
          infoIcon='assets/images/onboarding/fast-delivery.png'
          infoMessage='Enjoy varied food delivered to work before 11 a.m.'
        />
      </Box>

      <Divider
        variant='middle'
        sx={{
          backgroundColor: '#fff',
          width: '80%',
          m: 'auto',
          mt: 4,
        }}
      />

      <Box
        sx={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          mt: 4,
        }}
      >
        <Box
          sx={{
            fontFamily: 'Rubik',
            fontSize: '22px',
            fontWeight: 400,
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#fff',
            mt: 4,
          }}
        >
          {t(
            'Complete the registration below and enjoy fresh food tomorrow (order before 12:00 the day before)'
          )}

          <Box
            sx={{
              color: '#C0F98C',
            }}
          >
            {t('Or')}
          </Box>
          {t('Get in touch with us and talk to a real person:')}
        </Box>
        <Box
          sx={{
            color: '#C0F98C',
            textAlign: 'center',
            fontSize: '32px',
            fontWeight: 500,
            lineHeight: '38px',
            letterSpacing: '0em',
          }}
        >
          40 00 13 15
        </Box>
      </Box>

      <Divider
        variant='middle'
        sx={{
          backgroundColor: '#fff',
          width: '80%',
          m: 'auto',
          mt: 4,
        }}
      />

      <Box
        sx={{
          fontFamily: 'Cormorant Garamond',
          fontSize: { xs: '28px', md: '45px' },
          fontWeight: { sx: 700, md: 600 },
          lineHeight: { sx: '33px', md: '54px' },
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#fff',
          mt: { sx: 4, md: 8 },
        }}
      >
        {t('Signup for Lunsj På Døra')}
      </Box>
      <form onSubmit={handleSubmit(onSubmitCompanyOnBoardingPricing)}>
        <Box
          mt={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={headerSx}>{t('Where Do you want Lunsj Delivered?')}</Box>
          <Box
            gap={2}
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: '#fff',
              justifyContent: 'center',
            }}
          >
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='pin'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    placeholder={t('PIN')}
                    error={!!errors?.pin}
                    helperText={t(errors?.pin?.message)}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='city'
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('City')}
                    variant='outlined'
                    type='text'
                    error={!!errors?.city}
                    helperText={t(errors?.city?.message)}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='address'
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('Address')}
                    variant='outlined'
                    type='text'
                    error={!!errors?.address}
                    helperText={t(errors?.address?.message)}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={headerSx}>{t('Plans')}</Box>
          <Box
            sx={{
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '17px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: '#000',
              background: '#8FB869',
              borderRadius: '4px',
              width: smallerContainerWidth,
              padding: 1,
            }}
          >
            {t('First meal will be free')}
          </Box>
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
            }}
            gap={4}
          >
            <PricingOption
              isSelected={pricing === 1}
              amount={priceType.option1}
              info={t(
                'Employer pays kr 150 for each delivery. No Minimum order Per day'
              )}
              updateSelection={(e) => {
                if (pricing === 2) updatePricingState(1);
              }}
              mvaType='eks MVA'
            />
            <PricingOption
              title='Option 2'
              amount={priceType.option2}
              info={t('Transportation is included. Minimum 5 servings per day')}
              isSelected={pricing === 2}
              updateSelection={(e) => {
                if (pricing === 1) updatePricingState(2);
              }}
              mvaType='incl MVA and delivery'
            />
          </Stack>
          <Box sx={headerSx}>{t('Payment Plan')}</Box>
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '16px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {t('Employee Pays')}
          </Box>
          <Slider
            aria-label='Pricing'
            value={sliderValue}
            valueLabelDisplay='on'
            disabled={pricing === 2}
            step={1}
            marks={pricing === 1 ? marksType1 : marksType2}
            min={0}
            max={pricing === 1 ? priceType.option1 : priceType.option2}
            valueLabelFormat={(value) => `${value} kr`}
            sx={{
              width: smallerContainerWidth,
              color: '#fff',
              '& .MuiSlider-markLabel': {
                color: '#fff',
              },
            }}
            onChange={(e) => setSliderValue(e.target.value)}
          />
          <Stack
            direction={'row'}
            sx={{
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '23px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#8FB869',
              }}
            >
              {t('Lunch Start Date')}
            </Box>
            <DatePickerTwoDaysFutureComponent
              selectedDate={selectedDate}
              onDateSelected={(date) => {
                setSelectedDate(date);
              }}
            />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '16px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {t(
              'This is the start date for office lunch delivery; set meal preferences in the app by 12pm the preceding business day.'
            )}
          </Box>
          <Button variant='contained' sx={buttonStyle} type='submit'>
            {t('Next')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CompanyOnboardingPricing;
