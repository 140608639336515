import { Box, Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import CustomerReviewSection from './customer-reviews/CustomerReviewSection';
import DescriptionSection from './description-section/DescriptionSection';
import FooterSection from './footer/FooterSection';
import HeaderSection from './header-section/HeaderSection';
import ManagementViewsSection from './management-views-section/ManagementViewsSection';
import classes from './NewOnBoardingScreen.module.css';
const NewOnBoardingScreen = () => {
  const [pinButtonVisibility, setPinButtonVisibility] = useState(false);
  const primaryMenu = document.getElementById('primary-menu');
  if (primaryMenu) {
    primaryMenu.style.visibility = 'hidden';
  }
  const ref = useRef(null);

  function handlePinButtonCLick() {
    ref.current.scrollIntoView();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: {
          xs: '100%',
          md: '100%',
          lg: '100%',
          xl: '70vw',
        },
        margin: 'auto',
        flexDirection: 'column',
      }}
    >
      <HeaderSection
        changePinButtonVisibility={(visibility) =>
          setPinButtonVisibility(visibility)
        }
        ref={ref}
      />
      <DescriptionSection />
      <CustomerReviewSection />
      <ManagementViewsSection />
      <FooterSection />
      <Box
        className={classes.pinButton}
        sx={{
          visibility: pinButtonVisibility ? 'visible' : 'hidden',
          zIndex: 1000,
          width: {
            xs: '100%',
            md: '100%',
            lg: '100%',
            xl: '70vw',
          },
        }}
      >
        <Button
          variant='contained'
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Work Sans',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            padding: '12px 30px',
            borderRadius: '100px',
            background: '#8FB869',
          }}
          onClick={handlePinButtonCLick}
        >
          Prøv Gratis
        </Button>
      </Box>
    </Box>
  );
};

export default NewOnBoardingScreen;
