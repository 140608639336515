import React from 'react';
import DatePickerComponent, {
  DatePickerValue,
} from './components/DatePickerComponent';
import NewOnBoardingScreen from './pages/new-onboarding-screen/NewOnBoardingScreen';

const Test = () => {
  return <NewOnBoardingScreen />;
};

export default Test;
