import React,{ useEffect,useState } from "react";
import $ from "jquery";

import ReactGA from "react-ga4";

function GetInTouch(props) {

    const getInTouchClick = () => {
 
        ReactGA.event({ category: 'Home', action: 'Click', label: 'Get In Touch' }); 
    
    };
    
    const FORM_ENDPOINT = window.location.origin+'/v1/feedback';

    const [ ContactFormerror, setContactFormError ] = useState('');
    const [ ContactFormsuccess,setContactFormSuccess] = useState('');

    const handleContactFormSubmit = async (e) => {
        e.preventDefault();
        const { cFname, cLname, cEmail,cPhone,cCompany,cZip,cMessage } = e.target.elements;
        setContactFormError('');
        setContactFormSuccess("Please wait, while we're sending your reqest to our sales team.!");
        const data=
        {
            First_Name: cFname.value,
            Last_Name: cLname.value,
            Email: cEmail.value,
            Phone: cPhone.value,
            Company: cCompany.value,
            Zip: cZip.value,
            Message: cMessage.value,
            formType:'Contact Us Form'
        };
        console.log(JSON.stringify(data));
        $.ajax({
            type: "POST",
            url : FORM_ENDPOINT,
            dataType: "json",
            crossDomain: "true",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            success: function () {
                setContactFormSuccess("Well done, your request sent successfully. We'll contact you soon.");
                document.getElementById("contactForm").reset();
            },
            error: function () {
                setContactFormSuccess('');
                setContactFormError("Something went wrong, please try again or if the problem persists, please contact us for further assistance.");
                setTimeout(function(){
                setContactFormError('');
                },5000); 
            }});

    };


    let content = {
        English: {
            _section4Head1: "Get in Touch",
            _section4Head2: "TO GET A TASTE!",
            _section4Para: "If you have any questions, feedback, concerns, or you just want to chat with us to know more, please fill in this form and we will be happy to help you!",
            _section4L2: "Your First Name",
            _section4L3: "Your Last Name",
            _section4L4: "Email",
            _section4L5: "Phone number",
            _section4L6: "Company Name",
            _section4L7: "Zip Code",
            _section4L8: "Your Message",
            _section4Btn: "Get in Touch"
        },
        Norwegian: {
            _section4Head1: "La oss ta ta en prat for å",
            _section4Head2: "LA DEG FÅ EN SMAK!",
            _section4Para: "Har du spørsmål, tilbakemeldinger, bekymringer, eller vil du rett og slett vite mer, fyll inn noen punkter under og det vil glede oss å kunne hjelpe deg!",
            _section4L2: "Fornavn",
            _section4L3: "Etternavn",
            _section4L4: "Epost",
            _section4L5: "telefonnummer",
            _section4L6: "Firmanavn",
            _section4L7: "postnummer",
            _section4L8: "Din melding",
            _section4Btn: "Send inn",
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
    useEffect( () => {
        
        var bgSrc = $(".git").children("img").attr("src");
  
        var bgUrl = "url(" + bgSrc + ")";
        $(".git").parent().css("backgroundImage", bgUrl);
        $(".git").parent().addClass("bg-section");
        $(".git").remove();
      
    },[]);
  return (
    <section id="reservation1"   className="reservation bg-overlay bg-overlay-dark bg-parallax pt-90 pb-80">
    <div className="bg-section git">
        <img src="assets/images/background/7.webp" alt="Background" /> 
    </div>
    <div className="container">
        <div className="divider--shape-1up"></div>
        <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                <div className="heading heading-1 mb-50 text-center">
                    <p className="heading--subtitle">{content._section4Head1 }</p>
                    <h2 className="heading--title text-white">{content._section4Head2 }</h2>
                    <div className="divider--shape-4"></div>
                    <p className="heading--desc">
                        {content._section4Para }
                    </p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                <div className="reservation-form box-shadow mb-30 bg-white text-center">
                    <form onSubmit={handleContactFormSubmit} id="contacForm" className="getInTouchForm mb-0">
                        <div className="row">

                        <div className="col-xs-12 col-sm-12 col-md-12">
                                         <div  className="text-left">
                                         {ContactFormerror ? <p style={{color:"red"}} className="error-message ">{ContactFormerror}</p> : null}
                                         {ContactFormsuccess ? <p style={{color:"green"}} className="success-message ">{ContactFormsuccess}</p> : null}
                                         </div>
                        </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="text" className="form-control" name="fname" id="cFname" placeholder={content._section4L2 } required />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="text" className="form-control" name="lname" id="cLname" placeholder={content._section4L3 } required />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="email" className="form-control" name="email" id="cEmail" placeholder={content._section4L4 } />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="text" className="form-control" name="phone" id="cPhone" placeholder={content._section4L5 } required />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="text" className="form-control" name="company" id="cCompany" placeholder={content._section4L6 } required />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <input type="text" className="form-control" name="zipcode" id="cZip" placeholder={content._section4L7 } inputMode="numeric" pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$" required />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <textarea className="form-control" name="message" id="cMessage" rows="2" placeholder={content._section4L8 } required></textarea>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12">
                            <button type="submit" onClick={ getInTouchClick} class="btn btn--primary btn--lg">{content._section4Btn }</button>
                               
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12">

                                <div className="reservation-result"></div>
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </div>

        <div className="divider--shape-1down"></div>
    </div>

</section>
   
  );
}

export default GetInTouch;
