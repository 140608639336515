import React, { useState, useMemo } from 'react';

import countryCodeData from '../utils/country-code';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

const CountrySelector = (props) => {
  const { onCountrySelected, error } = props;
  const [countryCode, setCountryCode] = useState('+47');
  const options = useMemo(() => countryCodeData, []);
  const handleChange = (event) => {
    setCountryCode(event.target.value);
    onCountrySelected(event.target.value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <FormControl
        variant='outlined'
        style={{ minWidth: '90px' }}
        error={error ? 'error' : ''}
      >
        <Select value={countryCode} onChange={handleChange}>
          {options.map((country) => {
            return (
              <MenuItem
                key={country.code}
                value={country.dial_code}
                sx={{ minWidth: 120 }}
              >
                <div>{country.dial_code}</div>
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{error ? error : ''}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CountrySelector;
