import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { nbNO } from '@mui/x-date-pickers/locales';

import {
  dateFormatForServer,
  dateFormatToDisplay,
  getNextBusinessDay,
  twoDaysFromCurrentDate,
} from '../utils/dateUtils';
// either change en locale first day of week
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});
const DatePickerComponent = ({ date, onValidDateSelection, isValidDate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(date);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(t('Invalid Date'));
  const today = dayjs(new Date());

  useEffect(() => {
    const onDateChange = () => {
      if (isError) {
        // console.log('onDateSelection if', !isError);
        try {
          const selectedDate = dayjs(value?.$d);
          if (selectedDate?.isBefore(today)) {
            setError(t('Please select future Date'));
          } else setError(t('Invalid Date'));
        } catch (e) {
          setError(t('Invalid Date'));
        }
      } else {
        // console.log('onDateSelection ', !isError);
        setError(null);
        onValidDateSelection(value?.$d);
      }
    };
    onDateChange();
  }, [isError, onValidDateSelection, t, today, value]);

  const sxTextFieldHalf = {
    width: '50%',
    minWidth: '50%',
    backgroundColor: '#fff',
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={value}
        disablePast
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          isValidDate(isError);
          setIsError(params.error);
          return (
            <TextField
              variant='outlined'
              {...params}
              error={params.error}
              helperText={params.error ? error : ''}
              sx={sxTextFieldHalf}
              placeholder='Select'
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;

export const DatePickerTwoDaysFutureComponent = ({
  selectedDate,
  onDateSelected,
  sxTextFieldHalf = {
    width: '50%',
    minWidth: '50%',
    backgroundColor: '#fff',
  },
}) => {
  const handleChange = (newValue) => {
    console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      // localeText={
      //   nbNo.components.MuiLocalizationProvider.defaultProps.localeText
      // }
    >
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disablePast
        disableMaskedInput
        shouldDisableDate={twoDaysFromCurrentDate}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...sxTextFieldHalf,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
            disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};
