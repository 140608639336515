import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDR from './../locales/dr/translation.json';
import translationEN from './../locales/en/translation.json';
const resources = {
  en: {
    translation: translationEN,
  },
  dr: {
    translation: translationDR,
  },
};

i18n.use(initReactI18next).init(
  {
    resources,
    fallbackLng: 'dr',
    debug: false,
    lng: 'dr',
    dr: ['translations'],
    defaultDR: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  },
  function () {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem('language', language);
    } else {
      localStorage.setItem('language', 'Norwegian');
    }
  }
);
i18n.language = ['en', 'dr'];

export default i18n;
