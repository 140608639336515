import React,{ useEffect  } from "react";
import $ from "jquery";
import {  Link } from "react-router-dom";


function MenuHead(props) {
  
     
    let content=props.content;

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
    useEffect( () => {
        
        var bgSrc = $(".mhead").children("img").attr("src");
  
        var bgUrl = "url(" + bgSrc + ")";
        $(".mhead").parent().css("backgroundImage", bgUrl);
        $(".mhead").parent().addClass("bg-section");
        $(".mhead").remove();
      
    },[]);
  return (
    <section id="page-title" className="page-title bg-overlay bg-parallax bg-overlay-gradient">
    <div className="bg-section mhead">
        <img src={content._menuimg} alt="menu_bg" style={{marginTop: -300}} />
    </div>
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="title title-4 text-center">
                    <div className="title--content">
                        <div className="title--subtitle">{content._menuHead }</div>
                        <div className="title--heading">
                            <h1>{content._menuSubhead }</h1>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <ol className="breadcrumb">
                        <li><Link to="/">{content._menuB1 }</Link>
                        </li>
                        <li><Link to="/#menu7">{content._menuB2 }</Link>
                        </li>
                        <li className="active">{content._menuB3 }</li>
                    </ol>
                    <div className="divider--shape-1down"></div>
                </div>
              
            </div>
           
        </div>
       
    </div>
   
</section>
   
  );
}

export default MenuHead;
