import React,{ useEffect } from "react";
import $ from "jquery";



function WhyChoose(props) {

    let content = {
        English: {
            _section2Head1: "Why Choose",
_section2Head2: "LUNSJ PÅ DØRA",
_section2Subhead1: "For Employees",
_section2Subhead2: "For Administrators",
_section2h1: "Homemade comfort at the office desk",
_section2p1: "Bring the flavours of homecooked meals to your office desk. Our kitchens boast of a legacy of over 30 years and we’re bringing the richness of that legacy to you.",
_section2h2: "Control & flexibility",
_section2p2: "View meals for the week, select, cancel, switch and do so much more with your meal with our easy-to-use app.",
_section2h3: "Exciting menus you can look forward to",
_section2p3: "Explore new, innovative dishes everyday and periodically refreshed menus that will always surprise you!",
_section2h4: "The affordable luxury you deserve",
_section2p4: "Fresher than store bought meals, easier than preparing meals yourself… and more value for money!",
_section2h5: "From farm to plate",
_section2p5: "Our chefs use fresh & seasonal ingredients that have traveled a very short distance to reach you, focus on production, and allow for a more sustainable diet.",
_section2ha1: "Save time & efforts",
_section2pa1: "Give your employees the control to pick their own meals, saving time and effort for the administrators. Our centralized dashboard allows you to view and update your employee orders from an easy-to-use dashboard you can access at any point.",
_section2ha2: "Minimizing food waste",
_section2pa2: "By picking and customizing meal preferences a day in advance, you can help our cooks prepare only the required quantity of food and contribute to minimizing food wastage in a responsible way.",
_section2ha3: "Empower your people, build team spirit",
_section2pa3: "When your people are taken care of, they feel like they belong. Give your employees the opportunity to socialize with their colleagues and bond over a hearty, healthy meal, thus strengthening team spirit and higher satisfaction levels.",
_section2ha4: "Support local kitchens",
_section2pa4: "Instead of factory kitchens, Lunsj På Døra partners with popular local kitchens. Support local kitchens that prepare fresh food by ordering from these providers in your area.",
_section2ha5: "Flexible plans",
_section2pa5: "Whether you’re a team of 3 or 3000, we’ve got you covered with our range of packages and meal plans."
        },
        Norwegian: {
            _section2Head1: "Hvorfor velge",
            _section2Head2: " Lunsj på Døra",
            _section2Subhead1: "For ansatte",
            _section2Subhead2: "For administratorer",
            _section2h1: "Hjemmelaget komfort på arbeidsplassen",
            _section2p1: "Få inn smakene av hjemmelagde måltider til kontoret. Våre kjøkken nyter godt av over 30 års erfaring og vi bringer denne erfaringsrikdommen til deg.",
            _section2h2: "Kontroll og fleksibilitet",
            _section2p2: "Se ukens måltider, endre og gjør flere tilpasninger med måltidet ditt i vår enkle App.",
            _section2h3: "Spennende menyer å se frem til",
            _section2p3: "Utforsk nye, innovative retter hver dag og regelmessig fornyede menyer som alltid vil overraske deg!",
            _section2h4: "Luksusen du har råd til og fortjener",
            _section2p4: "Friskere enn maten du kjøper i butikken, enklere enn å lage maten selv…. og absolutt mer for pengene!",
            _section2h5: "Rett fra gården til tallerkenen",
            _section2p5: "Våre kokker bruker sesongens friske ingredienser som ikke har reist langt for å nå deg. Fokus på produksjon og altså en god porsjon bærekraft.",
            _section2ha1: "Spar tid og krefter",
            _section2pa1: "Gi de ansatte muligheten til å velge sine egne måltider, og spar tid og krefter på administrasjon. Vårt web-baserte dashbord lar deg se og oppdatere dine ansattes bestillinger. Du har kontinuerlig tilgang til dette.",
            _section2ha2: "Minimer matsvinn",
            _section2pa2: "Ved å velge og tilpasse måltidspreferanser en dag i forveien, kan du hjelpe våre kokker med å tilberede kun den nødvendige mengde mat og bidra til å minimere matsvinn på en ansvarlig måte.",
            _section2ha3: "Bygg opp folkene dine, skap lagånd",
            _section2pa3: "Når folkene dine blir tatt vare på, føler de at de hører til. Gi dine ansatte muligheten til å sosialisere med sine kolleger og knytte bånd over et solid, sunt måltid. Dette styrker dermed lagånden og gir høyere tilfredshet.",
            _section2ha4: "Støtt lokale kjøkken",
            _section2pa4: "I stedet for fabrikkkjøkken samarbeider Lunsj På Døra med populære lokalkjøkken.",
            _section2ha5: "Fleksible planer",
            _section2pa5: "Enten dere er et team på 3 eller 3000, så er vi dekket med vårt utvalg av pakker og måltidsplaner."
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);

    useEffect( () => {
        
          var bgSrc = $(".wch").children("img").attr("src");
    
          var bgUrl = "url(" + bgSrc + ")";
          $(".wch").parent().css("backgroundImage", bgUrl);
          $(".wch").parent().addClass("bg-section");
          $(".wch").remove();
        
      },[]);
  return (
    <section id="tabs1" className="tabs text-center bg-overlay bg-overlay-dark bg-parallax">
            <div className="bg-section wch">
            <img src="assets/images/background/8.jpg" alt="WhyChooseBG" /> 
            </div>
            <div className="container">
                <div className="divider--shape-1up"></div>
                <div className="row clearfix">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div className="heading heading-1 mb-50 text--center">
                            <p className="heading--subtitle">{content._section2Head1 }</p>
                            <h2 className="heading--title color-white">{content._section2Head2 }</h2>
                            <div className="divider--shape-4"></div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">

                        <ul className="nav nav-tabs">
                            <li className="active">
                                <a className="color-white" href="#About" data-toggle="tab">{content._section2Subhead1 }</a>
                            </li>
                            <li>
                                <a className="color-white" href="#History" data-toggle="tab">{content._section2Subhead2 }</a>
                            </li>
                        </ul>


                        <div className="tab-content">
                            <div className="tab-pane fade in banner-1 active" id="About">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <div className="heading heading-1 text--right pr-40">
                                            <ul style={{listStyle: 'none'}}>
                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2h1 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2p1 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2h2 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2p2 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2h3 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2p3 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2h4 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2p4 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2h5 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2p5 }
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="align--middle">
                                        <div className="col-xs-6 col-sm-6 col-md-3">
                                            <div className="mt-20">
                                                <img src="assets/images/banners/4.jpg" alt="bannerwhychoose1" style={{width: '100%'}} />
                                            </div>
                                        </div>

                                        <div className="col-xs-6 col-sm-6 col-md-3">
                                            <div>
                                                <img src="assets/images/banners/5.jpg" alt="bannerwhychoose2" style={{width: '100%'}} />
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="tab-pane fade in banner-5" id="History">
                                <div className="row">
                                    <div className="align--middle">
                                        <div className="col-xs-6 col-sm-6 col-md-3">
                                            <div>
                                                <img src="assets/images/banners/6.jpg" alt="bannerwhychoose3" style={{width: '100%'}} />
                                            </div>
                                        </div>

                                        <div className="col-xs-6 col-sm-6 col-md-3">
                                            <div className="mt-20">
                                                <img src="assets/images/banners/7.jpg" alt="bannerwhychoose4" style={{width: '100%'}} />
                                            </div>

                                        </div>
                                    </div>


                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <div className="heading heading-1 text--left pl-40">

                                            <ul style={{listStyle: 'none'}}>
                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2ha1 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2pa1 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2ha2 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2pa2 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2ha3 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2pa3 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2ha4 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2pa4 }
                                                    </p>
                                                </li>

                                                <li style={{marginBottom: 50}}>
                                                    <h6 className="color-theme">
                                                        {content._section2ha5 }
                                                    </h6>

                                                    <p style={{fontSize: 16, color: 'white'}}>
                                                        {content._section2pa5 }
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div className="divider--shape-1down"></div>
            </div>

        </section>
   
  );
}

export default WhyChoose;
