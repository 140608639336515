import React from "react";




function StickyBtn(props) {

    let content = {
        English: {
            _stickyBtn: "GET A FREE TRIAL"
        },
        Norwegian: {
            _stickyBtn: "Prøv gratis"
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <div className="module module-reservation">
      <a href="#" className="floatButton float-button-mobile-down" data-toggle="modal" data-target="#reservationModule1">{content._stickyBtn }</a>

    </div>
   
  );
}

export default StickyBtn;
