import React from "react";



function Location(props) {

    let content = {
        English: {
            _footerHead: "OUR LOCATIONS"
        },
        Norwegian: {
            _footerHead: "Her leverer vi"
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <section id="loc" className="banner banner-2 text-center bg-white">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <h2 className="heading--title" style={{marginTop: 0}}>
                            {content._footerHead }
                        </h2>
                        <div className="divider--shape-4"></div>
                        <center>
                            <div style={{overflow: 'auto'}}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>Sarpsborg</td>
                                        <td>Fredrikstad</td>
                                        <td>Råde</td>
                                        <td>Rygge</td>
                                        <td>Moss</td>
                                        <td>Vestby</td>
                                        <td>Ås</td>
                                    </tr>
                                    <tr>
                                        <td>Drøbak</td>
                                        <td>Ski</td>
                                        <td>Indre Østfold</td>
                                        <td>Spydeberg</td>
                                        <td>Askim</td>
                                        <td>Mysen</td>
                                        <td>Oppegård</td>
                                    </tr>
                                    <tr>
                                        <td>Oslo</td>
                                        <td>Lørenskog</td>
                                        <td>Lillestrøm</td>
                                        <td>Skedsmo</td>
                                        <td>Bærum</td>
                                        <td>Asker</td>
                                        <td>Drammen</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </section>
   
  );
}

export default Location;
