import React, { useEffect, useState } from 'react';
import {
  Footer,
  GetInTouch,
  GetTheApp,
  Location,
  Menu,
  MenuDaily,
  MenuHead,
  MenuMeaty,
  MenuVeg,
  Modals,
  Process,
  Slider,
  StickyBtn,
  Testi,
  TopBar,
  WhyChoose,
} from './components';

import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';

import { Outlet, useLocation } from 'react-router-dom';
import i18n from './utils/i18n';
import { initGA } from './ga-utils';

function App() {
  const location = useLocation();
  const previousPage = location.state != null ? location.state.from : '/';
  let languageStoredInLocalStorage = localStorage.getItem('language');

  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : 'Norwegian'
  );

  if (language === 'English') {
    i18n.changeLanguage('en');
  } else {
    i18n.changeLanguage('dr');
  }

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    //testing locally
    handleAcceptCookie();

    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  console.log(`locations: ${(pathname, hash, key)}`);

  const isUserOnboardingPage =
    pathname.includes('app-user-registration') ||
    pathname.includes('company-registration');

  return (
    <>
      <input type='hidden' name='previousPage' value={previousPage} />
      <div id='wrapper' className='wrapper clearfix'>
        <TopBar
          language={language}
          handleSetLanguage={(language) => {
            setLanguage(language);
            storeLanguageInLocalStorage(language);
          }}
        />
        <Outlet />
        {isUserOnboardingPage ? '' : <Footer language={language} />}
      </div>
      {isUserOnboardingPage ? (
        ''
      ) : (
        <>
          <StickyBtn language={language} />
          <Modals language={language} />
        </>
      )}

      <div className='App'>
        <CookieConsent
          enableDeclineButton
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
        >
          We use cookies to enhance your browsing experience and improve our
          website. By continuing to use our site, you agree to the placement of
          cookies on your device. These cookies collect information such as
          browsing behaviour. You can manage and control cookie preferences in
          your browser settings. For more information, please review our{' '}
          <a href='assets/privacy.pdf' target='_blank'>
            Privacy Policy
          </a>
          .
        </CookieConsent>
      </div>
    </>
  );
}

export function Home(props) {
  const primaryMenu = document.getElementById('primary-menu');
  if (primaryMenu) {
    primaryMenu.style.visibility = '';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [language, setLangage] = useState(
    i18n.language === 'en' ? 'English' : 'Norwegian'
  );

  i18n.on('languageChanged', () => {
    setLangage(i18n.language === 'en' ? 'English' : 'Norwegian');
    console.log('languageChanged', language);
  });

  return (
    <>
      <Slider language={language} />
      <Process language={language} />
      <WhyChoose language={language} />
      <Menu language={language} />
      <GetInTouch language={language} />
      <GetTheApp language={language} />
      <Testi language={language} />
      <Location language={language} />
    </>
  );
}

export function Menumeaty(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'English' : 'Norwegian'
  );

  i18n.on('languageChanged', () => {
    setLanguage(i18n.language === 'en' ? 'English' : 'Norwegian');
  });

  let content = {
    English: {
      _menuHead: 'Discover',
      _menuSubhead: 'Our Menu',
      _menuB1: 'Home',
      _menuB2: 'Menu',
      _menuB3: 'Meaty Goodness',
      _menuimg: 'assets/images/menu/lunsjwrap.jpg',
    },
    Norwegian: {
      _menuHead: ' ',
      _menuSubhead: 'Menyen vår',
      _menuB1: 'Hjem',
      _menuB2: 'Meny',
      _menuB3: 'Kjøttfull godhet',
      _menuimg: 'assets/images/menu/lunsjwrap.jpg',
    },
  };
  return (
    <>
      <MenuHead language={language} content={content} />
      <MenuMeaty language={language} />
    </>
  );
}

export function Menuveg(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  let content = {
    English: {
      _menuHead: 'Discover',
      _menuSubhead: 'Our Menu',
      _menuB1: 'Home',
      _menuB2: 'Menu',
      _menuB3: 'Vegetarian Fressness',
      _menuimg: 'assets/images/menu/4.jpg',
    },
    Norwegian: {
      _menuHead: ' ',
      _menuSubhead: 'Menyen vår',
      _menuB1: 'Hjem',
      _menuB2: 'Meny',
      _menuB3: 'Vegetarisk fressness',
      _menuimg: 'assets/images/menu/4.jpg',
    },
  };

  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'English' : 'Norwegian'
  );

  i18n.on('languageChanged', () => {
    setLanguage(i18n.language === 'en' ? 'English' : 'Norwegian');
  });

  return (
    <>
      <MenuHead language={language} content={content} />
      <MenuVeg language={language} />
    </>
  );
}

export function Menudaily(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  let content = {
    English: {
      _menuHead: 'Discover',
      _menuSubhead: 'Our Menu',
      _menuB1: 'Home',
      _menuB2: 'Menu',
      _menuB3: 'Daily Fare',
      _menuimg: 'assets/images/menu/bakst.jpg',
    },
    Norwegian: {
      _menuHead: ' ',
      _menuSubhead: 'Menyen vår',
      _menuB1: 'Hjem',
      _menuB2: 'Meny',
      _menuB3: 'Daglig pris',
      _menuimg: 'assets/images/menu/bakst.jpg',
    },
  };
  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'English' : 'Norwegian'
  );

  i18n.on('languageChanged', () => {
    setLanguage(i18n.language === 'en' ? 'English' : 'Norwegian');
  });

  return (
    <>
      <MenuHead language={language} content={content} />
      <MenuDaily language={language} />
    </>
  );
}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem('language', language);
}
export default App;
