import { Box, Rating, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './HeaderInfoSection.module.css';
import IconLabelComponentNew from './IconLabelComponentNew';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FFFFFF',
  },
  '& .MuiRating-iconHover': {
    color: '#FFFFFF',
  },
});

const HeaderInfoSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        gap: 4,
      }}
    >
      <Box
        sx={{
          marginTop: { xs: 0, lg: '60px' },
          marginLeft: { lg: '50px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: {
            xs: 'center',
            lg: 'flex-start',
          },
          padding: {
            xs: '0 16px 16px 16px',
            lg: 2,
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'content',
              lg: 'none',
            },
          }}
        >
          <img
            alt='Logo'
            src={
              process.env.PUBLIC_URL +
              '/assets/images/onboarding/food-bowl-half.png'
            }
            width='100%'
          />
        </Box>
        <Box
          sx={{
            mt: {
              xs: 2,
              lg: 0,
            },
          }}
        >
          <img
            alt='Logo'
            src={
              process.env.PUBLIC_URL +
              '/assets/images/logo/Logo_Lunsj_på_døra1.svg'
            }
            height='120px'
            style={{
              marginTop: {
                xs: '16px',
                lg: '0px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'stretch',
              lg: 'flex-start',
            },
            minWidth: '100%',
            gap: 2,
          }}
        >
          <Box className={classes.title}>Ikke kantine på jobben?</Box>
          <Box
            className={classes.subtitle}
            component='span'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            Det har vi løst for deg - Digital kantine for kun
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box className={classes.infoText1}>Kr 79</Box>
            <Box className={classes.infoText2}>20% off</Box>
            <Box className={classes.infoText3}>kr 99</Box>
            <Box className={classes.infoText4}>(+ mva og frakt)</Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: {
                sm: '60%',
                lg: '34%',
              },
              left: {
                sm: '-30px',
                lg: '-20px',
                xl: '15%',
              },
            }}
          >
            <img
              src='assets/images/onboarding/splash.svg'
              alt='splash'
              width='100%'
              height='100%'
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              justifyContent: 'center',
              alignItems: 'stretch',
              gap: 4,
              mt: 4,
            }}
          >
            <IconLabelComponentNew
              infoIcon='assets/images/onboarding/order-food.png'
              infoMessage='Klikk for å følge en meny. Velg levering på faste dager eller bestill sporadisk'
            />
            <IconLabelComponentNew
              infoIcon='assets/images/onboarding/cooking.png'
              infoMessage='Hver dag lager vi maten du har bestilt, med ferske ingredienser'
            />
            <IconLabelComponentNew
              infoIcon='assets/images/onboarding/fast-delivery.png'
              infoMessage='Vi leverer maten rett på arbeidsplassen før kl 11'
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#364564',
          padding: { xs: 3, lg: '18px 100px 18px 50px' },
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box component='span' className={classes.subtitleWhite}>
            Over <strong>1000</strong> app nedlastinger
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'contents',
            },
          }}
        >
          |
        </Box>
        <StyledRating name='read-only' value={4.5} precision={0.5} readOnly />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box component='span' className={classes.subtitleWhite}>
            Stjerners vurdering
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderInfoSection;
