import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';
import ReactGA from 'react-ga4';

function Footer(props) {
  const FORM_ENDPOINT = window.location.origin + '/v1/feedback';

  const [FooterFormerror, setFooterFormError] = useState('');
  const [FooterFormsuccess, setFooterFormSuccess] = useState('');

  const getInClick = () => {
    ReactGA.event({
      category: 'Home',
      action: 'Click',
      label: 'TRY THE LUNSJ PÅ DØRA EXPERIENCE',
    });
  };

  const handleFooterFormSubmit = async (e) => {
    e.preventDefault();
    const { ffName, ffEmail, ffPhone } = e.target.elements;
    setFooterFormError('');
    setFooterFormSuccess('Please wait...');
    const data = {
      Name: ffName.value,
      Email: ffEmail.value,
      Phone: ffPhone.value,
      formType: 'Footer Form',
      // Zip: ffPostalCode.value,
    };
    console.log(JSON.stringify(data));
    $.ajax({
      type: 'POST',
      url: FORM_ENDPOINT,
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function () {
        setFooterFormSuccess("Thank you! We'll contact you soon.");
        document.getElementById('footerForm').reset();
      },
      error: function () {
        setFooterFormSuccess('');
        setFooterFormError('Something went wrong!');
        setTimeout(function () {
          setFooterFormError('');
        }, 5000);
      },
    });
  };

  let content = {
    English: {
      _footerPara:
        'Fill in the form below <br />to experience the flavors of <br />Lunsj På Døra for free',
      _footerL1: 'Your Name',
      _footerL2: 'Email',
      _footerL3: 'Phone Number',
      _footerBtn: 'TRY THE LUNSJ PÅ DØRA EXPERIENCE',
      _footerSubhead1: 'Our address',
      _footerBtn1: 'VIEW ON MAP',
      _footerSubhead2: 'Contact Us:',
      _footerBtn2: 'SEND A MESSAGE',
      _footerPara1:
        'If you’d like to talk to our sales team immediately, contact',
    },
    Norwegian: {
      _footerPara:
        'Fyll ut punktene under for å oppleve smakene av <br />Lunsj på Døra - gratis!',
      _footerL1: 'Ditt navn',
      _footerL2: 'epost',
      _footerL3: 'telefonnummer',
      _footerBtn: 'Prøv Lunsj På Døra Opplevelsen',
      _footerSubhead1: 'Vår adresse',
      _footerBtn1: 'SE I KART',
      _footerSubhead2: 'Kontakt oss:',
      _footerBtn2: 'SEND EN MELDING',
      _footerPara1:
        'Hvis du vil snakke med noen fra vårt salgsteam umiddelbart, kontakt:',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <footer id='footer' className='footer text-center'>
      <div className='footer--widget text--center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-3 col-md-4'>
              <div className='footer--widget-content'>
                <h6 className='color-white'>{content._footerSubhead1}</h6>
                <p>
                  Ole Deviks Vei 10, <br />
                  0666 Oslo
                </p>

                <a
                  href='https://goo.gl/maps/D5F3e4FkiGgtgvsc7'
                  target='_blank'
                  className='btn btn--primary btn--bordered'
                  style={{ marginTop: 20 }}
                >
                  {content._footerBtn1}
                </a>
              </div>
              <div className='footer--widget-content mt-30'>
                <a href='https://conluo.no'>
                  <img
                    src='assets/images/banners/Conlu_logo.png'
                    style={{
                      width: '100%',
                      paddingLeft: 70,
                      paddingRight: 70,
                      marginBottom: 10,
                    }}
                  />
                </a>
                <p>Conluo Mat AS, organisasjonsnummer: 920 829 740</p>
                <img
                  src='assets/images/Certificate-Logos.png'
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            <div className='col-xs-12 col-sm-6 col-md-4'>
              <div className='footer--reservation'>
                <p
                  className='heading--subtitle'
                  style={{ fontSize: 20, lineHeight: 1.5, color: '#8fb869' }}
                >
                  {HTMLReactParser(content._footerPara)}
                </p>
                <h4></h4>

                <div className='footer--reservation-wrapper'>
                  <form
                    onSubmit={handleFooterFormSubmit}
                    id='footerForm'
                    className='reservationForm mb-0'
                  >
                    <div className='row'>
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <div className='text-left'>
                          {FooterFormerror ? (
                            <p
                              style={{ color: 'red' }}
                              className='error-message '
                            >
                              {FooterFormerror}
                            </p>
                          ) : null}
                          {FooterFormsuccess ? (
                            <p
                              style={{ color: 'white' }}
                              className='success-message '
                            >
                              {FooterFormsuccess}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <input
                          type='text'
                          className='form-control'
                          name='res-fname'
                          id='ffName'
                          placeholder={content._footerL1}
                          required
                          style={{
                            marginBottom: '10 !important',
                            height: '38 !important',
                          }}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <input
                          type='email'
                          className='form-control'
                          name='res-email'
                          id='ffEmail'
                          placeholder={content._footerL2}
                          style={{
                            marginBottom: '10 !important',
                            height: '38 !important',
                          }}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <input
                          type='text'
                          className='form-control'
                          name='res-phone'
                          id='ffPhone'
                          placeholder={content._footerL3}
                          required
                          style={{
                            marginBottom: '10 !important',
                            height: '38 !important',
                          }}
                        />
                      </div>

                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <input
                          type='submit'
                          onClick={getInClick}
                          value={content._footerBtn}
                          name='submit'
                          className='btn--primary btn--block'
                          style={{
                            fontSize: 14,
                            fontWeight: 800,
                            textTransform: 'uppercase',
                          }}
                        />
                      </div>
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <div className='reservation-result'></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-12'>
                <h6 className='color-white'>{content._footerPara1}</h6>
                <div className='col-xs-12 col-sm-12 col-md-6'>
                  <p>
                    <span className='color-theme'>Sebastian Fredstad</span>
                    <br />
                    sebastian@conluo.no
                    <br />
                    tlf: 478 38 933
                  </p>
                </div>

                <div className='col-xs-12 col-sm-12 col-md-6'>
                  <p>
                    <span className='color-theme'>Valeria</span>
                    <br />
                    Valeria@conluo.no
                    <br />
                    tlf: +47 983 68 792
                  </p>
                </div>
              </div>
            </div>

            <div className='col-xs-12 col-sm-3 col-md-4'>
              <div className='footer--widget-content'>
                <h6 className='color-white'>{content._footerSubhead2}</h6>
                <p className='mb-0'>lunsjpadora@conluo.no</p>
                <p className='mb-0'>tlf: 40 00 13 15</p>

                <a
                  data-toggle='modal'
                  data-target='#contactUsModule'
                  className='btn btn--primary btn--bordered'
                  style={{ marginTop: 20 }}
                >
                  {content._footerBtn2}
                </a>
              </div>
              <div className='footer--widget-content mt-50'>
                <div className='footer--social-icons mt-50'>
                  <a
                    href='https://www.facebook.com/lunsjpadora'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-facebook'></i>
                  </a>
                  <a
                    href='https://www.instagram.com/lunsjpadora'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-instagram'></i>
                  </a>
                  <a
                    href='https://www.linkedin.com/company/lunsj-p%C3%A5-d%C3%B8ra/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-linkedin-square'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer--social'>
        <div className='container'>
          <div className='row'></div>
        </div>
      </div>

      <div className='footer--copyright text-center'>
        <div className='divider--shape-dark'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12'>
              <span>
                &copy; Copyright
                <a target='_blank' href='https://conluo.no'>
                  {' '}
                  Conluo Facility Services AS
                </a>{' '}
                2023 | Made with{' '}
                <i style={{ color: '#a83f39' }} className='fa fa-heart'></i> by
                <a target='_blank' href='https://dcx.com'>
                  {' '}
                  Digital Cloud Xperience AS
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
