import { Box, Grid } from '@mui/material';
import React from 'react';
import classes from './CustomerReviewSection.module.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReviewCard from './ReviewCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReviewCardSlider from './ReviewCardSlider';

const reviewPersonInfo = [
  {
    isGreen: true,
    comment:
      'En av de beste funksjonene med appen er den enkle og brukervennlige muligheten til å endre hvilket måltid man ønsker. Du kan legge inn allergier og preferanser, slik at det blir enkelt for meg og de andre ansatte å tilpasse maten i hverdagen. Enten det er veg eller hallal.',
    name: 'Ole Jacob Knudsen',
    company: 'Svenn AS',
    img: 'assets/images/onboarding/persons/Ole_jacob.png',
  },
  {
    isGreen: false,
    comment:
      'Vi har valgt Lunsj På Døra fordi vi får enkle porsjoner hver eneste dag som varierer og er veldig, veldig gode og ganske sunne. Min favoritt er Bahn Mi. Den er kjempegod og så får vi en del ulike salater og andre varmretter som passer veldig godt til lunsj hos oss.',
    name: 'Ruben Toftdahl',
    company: 'Svenn AS',
    img: 'assets/images/onboarding/persons/Jon_espen.png',
  },
  {
    isGreen: true,
    comment:
      'Vi valgte Lunsj På Døra fordi vi ikke hadde noen kantine i bygget her og vi trengte egentlig noe god mat. Vi ble tipset om at Lunsj på Døra fantes. Nå har vi testet dette i godt over ett år. Det har egentlig vært et kjempebra opplegg. Det er jo godt utvalg av mat og en av mine favoritter er lakse-wrap.',
    name: 'Vegard Dahle',
    company: 'Kappa Bioscience AS',
    img: 'assets/images/onboarding/persons/Pal.png',
  },
  {
    isGreen: false,
    comment:
      'For øvrig fått strålende respons på appen deres fra gjengen på kontoret, terningkast syv!',
    name: 'Andrea Grøndal Berg',
    company: 'Inbound',
    img: 'assets/images/onboarding/persons/Andrea.png',
  },
];

const CustomerReviewSection = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 8,
      }}
    >
      <Box className={classes.title}>Se hva kundene våre sier</Box>
      <Box
        className={classes.points}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          gap: { xs: '4px', sm: 1, lg: 2 },
        }}
      >
        Stressfritt
        <FiberManualRecordIcon fontSize='small' sx={{ color: '#364564' }} />
        Sparer tid
        <FiberManualRecordIcon fontSize='small' sx={{ color: '#364564' }} />
        Sparer penger
      </Box>
      {matches ? (
        <Grid
          container
          sx={{
            mt: 8,
            p: 2,
            pb: 8,
            overflox: 'scroll',
          }}
        >
          <Grid item md={3}>
            <ReviewCard {...reviewPersonInfo[0]} />
          </Grid>
          <Grid item md={3}>
            <ReviewCard {...reviewPersonInfo[1]} />
          </Grid>
          <Grid item md={3}>
            <ReviewCard {...reviewPersonInfo[2]} />
          </Grid>
          <Grid item md={3}>
            <ReviewCard {...reviewPersonInfo[3]} />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            mt: 8,
            p: 3,
            pb: 8,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ReviewCardSlider reviewPersonInfo={reviewPersonInfo} />
        </Box>
      )}
    </Box>
  );
};

export default CustomerReviewSection;
