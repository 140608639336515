import { Box } from '@mui/material';
import React from 'react';
import ManagementView from './ManagementView';
import classes from './ManagementViewsSection.module.css';
const ManagementViewsSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        gap: 6,
        padding: { xs: 3, lg: 8 },
      }}
    >
      <Box className={classes.title}>Vår Ledelses Synspunkter</Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: 6,
        }}
      >
        <ManagementView
          name='Navdeep Kumar'
          desig='Konsernsjef Conluo'
          message={`Når vi startet opp med «Lunsj på Døra» hadde vi ikke regnet med at responsen skulle være så formidabel. Vi er virkelig stolte. 3 faktorer er avgjørende for å lykkes med et slikt konsept: 
  1. Maten må være velsmakende, sunn og variabel 
  2. Maten må komme fram til kundene våre hver dag på samme tid, som forventet og 
  3. Appen hvor du som kunde administrerer lunsjen din må være svært enkel og effektiv å bruke for at du skal synes dette er effektivt og praktisk.
Vi ser ut til å ha lykkes langt på vei med alle 3 og jeg tror det er takket være god samhandling i teamene og nitidig trimming av rutiner`}
          img='assets/images/onboarding/persons/Navdeep.png'
        />
        <ManagementView
          name='Johan Petterson'
          desig='Faglig ansvarlig kokk'
          message='Vi har en god stund nå virkelig lagt oss i selen for finne frem til spennende retter og stor grad av variasjon. Vi har for det meste også fått gode tilbakemeldinger fra de som spiser maten også. Nå er det imidlertid en ting jeg gleder meg veldig til: I høst blir endelig en Feedback-knapp tilgjengelig i appen vår. Du kan trille terning på den maten jeg sender til deg. Kokker elsker å få høre hva gjestene synes om maten. Jeg er intet unntak.'
          img='assets/images/onboarding/persons/Johan.png'
        />
      </Box>
    </Box>
  );
};

export default ManagementViewsSection;
