import { Box } from '@mui/material';
import React from 'react';
import classes from './FooterSection.module.css';
import FooterSocialMediaSection from './FooterSocialMediaSection';

const FooterSection = () => {
  return (
    <Box
      sx={{
        padding: '32px 50px 48px 50px',
        backgroundColor: '#364564',
        marginBottom: '140px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: 4,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            justifyContent: 'center',
            gap: {
              xs: 4,
              lg: '52px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: {
                xs: 'center',
                lg: 'left',
              },
            }}
          >
            <Box className={classes.title}>Kontakt oss</Box>
            <Box className={classes.subtitle}>
              {'lunsjpadora@conluo.no \nSebastian Fredstad\ntlf: 47 478 38 933'}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: {
                xs: 'center',
                lg: 'left',
              },
            }}
          >
            <Box className={classes.title}>Vår adresse</Box>
            <Box className={classes.subtitle}>Ole Deviks Vei 10,0666 Oslo</Box>
          </Box>
        </Box>
        <FooterSocialMediaSection />
      </Box>
    </Box>
  );
};

export default FooterSection;
