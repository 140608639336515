export const buttonStyle = {
  backgroundColor: '#8FB869',
  color: '#FFFFFF',
  paddingX: 16,
  paddingY: 2,
  fontFamily: 'Raleway',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#364564',
    borderColor: '#364564',
    color: '#ffffff',
  },
  '&.Mui-disabled': {
    background: '#eaeaea',
    color: '#c0c0c0',
  },
};
