import { Box } from '@mui/material';
import React from 'react';
import FooterSection from './footer/FooterSection';
import classes from './FormSubmittedInfoScreen.module.css';
const sxContainer = {
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#354465',
  gap: 10,
  maxWidth: {
    xs: '100%',
    md: '100%',
    lg: '100%',
    xl: '70vw',
  },
};

const primaryMenu = document.getElementById('primary-menu');
if (primaryMenu) {
  primaryMenu.style.visibility = 'hidden';
}

const FormSubmittedInfoScreen = () => {
  return (
    <>
      <Box sx={sxContainer}>
        <Box
          mt={5}
          sx={{
            height: { xs: '74vh', lg: '88vh' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 6,
            color: '#364564',
          }}
        >
          <img
            alt='Logo'
            src={
              process.env.PUBLIC_URL + '/assets/images/onboarding/lunch-img.svg'
            }
            height='120px'
          />
          <Box
            sx={{
              fontFamily: 'Work Sans',
              fontSize: '36px',
              fontWeight: '700',
              lineHeight: '40px',
              textAlign: 'center',
            }}
          >
            Gratulerer!
          </Box>
          <Box
            sx={{
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            Du har nå fullført registreringen din hos Lunsj på Døra!
          </Box>
          <Box
            sx={{
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            Vi vil ta kontakt med deg for å planlegge den gratis prøveperioden.
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box className={classes.title}>Følg oss på sosiale medier</Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 3,
              }}
            >
              <img
                src='assets/images/social/solid-instagram.svg'
                width={'28px'}
                height={'28px'}
                alt='instagram'
                className={classes.icon}
                onClick={() => {
                  window.open('https://www.instagram.com/lunsjpadora');
                }}
              />
              <img
                src='assets/images/social/solid-facebook.svg'
                width={'28px'}
                height={'28px'}
                alt='facebook'
                onClick={() => {
                  window.open('https://www.facebook.com/lunsjpadora');
                }}
                className={classes.icon}
              />
              <img
                src='assets/images/social/solid-linkedin.svg'
                width={'28px'}
                height={'28px'}
                alt='linkedin'
                className={classes.icon}
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/company/lunsj-p%C3%A5-d%C3%B8ra/'
                  );
                }}
              />
            </Box>
            <Box className={classes.link}>
              besøk:{' '}
              <a
                href='https://lunsjpadora.no'
                target='_blank'
                rel='noreferrer'
                className={classes.url}
              >
                https://lunsjpadora.no/
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: { xs: '26vh', lg: '12vh' },
            width: '100%',
          }}
        >
          <FooterSection />
        </Box>
      </Box>
    </>
  );
};

export default FormSubmittedInfoScreen;
