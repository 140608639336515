import { Box, Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyOnboardingFinalPage = ({ onClickNext }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        flexDirection: 'column',
        position: 'relative',
        p: '5%',
        mt: {
          xs: '20%',
          sm: '10%',
          md: '5%',
        },
      }}
    >
      <Stack
        sx={{
          fontFamily: 'Cormorant Garamond',
          fontSize: { xs: '28px', md: '45px' },
          fontWeight: { xs: 700, md: 600 },
          lineHeight: '54px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#fff',
          alignItems: 'center',
        }}
      >
        <Box>{t('Your request has been successfully submitted!')}</Box>
        <Box
          mt={4}
          sx={{
            fontSize: { xs: '18px', md: '24px' },
            lineHeight: { xs: '22px', md: '30px' },
          }}
        >
          {t('We will contact you to confirm your registration')}
        </Box>
        <Button
          variant='contained'
          sx={{
            backgroundColor: '#8FB869',
            color: '#FFFFFF',
            paddingX: 16,
            paddingY: 2,
            fontFamily: 'Raleway',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '16px',
            letterSpacing: '0em',
            textAlign: 'center',
            mt: {
              xs: '20%',
              md: '40%',
            },
            '&:hover': {
              backgroundColor: '#364564',
              borderColor: '#364564',
              color: '#ffffff',
            },
          }}
          onClick={onClickNext}
        >
          {t('Next')}
        </Button>
      </Stack>
    </Box>
  );
};

export default CompanyOnboardingFinalPage;
