import React, { useState } from 'react';
import $ from 'jquery';

function Modals(props) {
  const FORM_ENDPOINT = window.location.origin + '/v1/feedback';

  const [TrialFormerror, setTrialFormError] = useState('');
  const [TrialFormsuccess, setTrialFormSuccess] = useState('');

  const [officeadminFormerror, setofficeadminFormError] = useState('');
  const [officeadminFormsuccess, setofficeadminFormSuccess] = useState('');

  const [CompanyFormerror, setCompanyFormError] = useState('');
  const [CompanyFormsuccess, setCompanyFormSuccess] = useState('');

  const [ContactModalFormerror, setContactModalFormError] = useState('');
  const [ContactModalFormsuccess, setContactModalFormSuccess] = useState('');

  const handleTrialFormSubmit = async (e) => {
    e.preventDefault();
    const { trialName, trialEmail, trialPhone, trialZip } = e.target.elements;
    setTrialFormError('');
    setTrialFormSuccess(
      'Please wait, while we accepting your free trial request!'
    );
    const data = {
      Name: trialName.value,
      Email: trialEmail.value,
      Phone: trialPhone.value,
      Zip: trialZip.value,
      formType: 'Free Trial Form',
    };
    console.log(JSON.stringify(data));
    $.ajax({
      type: 'POST',
      url: FORM_ENDPOINT,
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function () {
        setTrialFormSuccess(
          "Well done, your request for free trial received successfully. We'll contact you soon."
        );
        document.getElementById('trialForm').reset();
      },
      error: function () {
        setTrialFormSuccess('');
        setTrialFormError(
          'Something went wrong, please try again or if the problem persists, please contact us for further assistance.'
        );
        setTimeout(function () {
          setTrialFormError('');
        }, 5000);
      },
    });
  };

  const handleOfficeAdminFormSubmit = async (e) => {
    e.preventDefault();
    const {
      officeadminName,
      officeadminCompony,
      officeadminPhone,
      officeadminMob,
      officeadminZip,
      officeadminEmail,
    } = e.target.elements;
    setofficeadminFormError('');
    setofficeadminFormSuccess('Please wait, while we accepting your request!');
    const data = {
      Name: officeadminName.value,
      Company: officeadminCompony.value,
      Phone: officeadminPhone.value,
      Admin_Phone: officeadminMob.value,
      Zip: officeadminZip.value,
      Email: officeadminEmail.value,
      formType: 'Office Admin Form',
    };
    console.log(JSON.stringify(data));
    $.ajax({
      type: 'POST',
      url: FORM_ENDPOINT,
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function () {
        setofficeadminFormSuccess(
          "Well done, your request received successfully. We'll contact you soon."
        );
        document.getElementById('officeadminForm').reset();
      },
      error: function () {
        setofficeadminFormSuccess('');
        setofficeadminFormError(
          'Something went wrong, please try again or if the problem persists, please contact us for further assistance.'
        );
        setTimeout(function () {
          setofficeadminFormError('');
        }, 5000);
      },
    });
  };

  const handleCompanyFormSubmit = async (e) => {
    e.preventDefault();
    const {
      companyRepName,
      companyName,
      companyAdd,
      companyZip,
      companyEmail,
      companyDetails,
    } = e.target.elements;
    setCompanyFormError('');
    setCompanyFormSuccess('Please wait, while we accepting your request!');
    const data = {
      Name: companyRepName.value,
      Company_Name: companyName.value,
      Company_Add: companyAdd.value,
      Zip: companyZip.value,
      Email: companyEmail.value,
      Company_Details: companyDetails.value,
      formType: 'Register My Company Form',
    };
    console.log(JSON.stringify(data));
    $.ajax({
      type: 'POST',
      url: FORM_ENDPOINT,
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function () {
        setCompanyFormSuccess(
          "Well done, your request received successfully. We'll contact you soon."
        );
        document.getElementById('companyForm').reset();
      },
      error: function () {
        setCompanyFormSuccess('');
        setCompanyFormError(
          'Something went wrong, please try again or if the problem persists, please contact us for further assistance.'
        );
        setTimeout(function () {
          setCompanyFormError('');
        }, 5000);
      },
    });
  };

  const handleContactModalFormSubmit = async (e) => {
    e.preventDefault();
    const { cmFname, cmLname, cmEmail, cmPhone, cmCompany, cmZip, cmMessage } =
      e.target.elements;
    setContactModalFormError('');
    setContactModalFormSuccess(
      "Please wait, while we're sending your reqest to our sales team.!"
    );
    const data = {
      First_Name: cmFname.value,
      Last_Name: cmLname.value,
      Email: cmEmail.value,
      Phone: cmPhone.value,
      Company: cmCompany.value,
      Zip: cmZip.value,
      Message: cmMessage.value,
      formType: 'Contact Us Modal',
    };
    console.log(JSON.stringify(data));
    $.ajax({
      type: 'POST',
      url: FORM_ENDPOINT,
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function () {
        setContactModalFormSuccess(
          "Well done, your request sent successfully. We'll contact you soon."
        );
        document.getElementById('contactmodalForm').reset();
      },
      error: function () {
        setContactModalFormSuccess('');
        setContactModalFormError(
          'Something went wrong, please try again or if the problem persists, please contact us for further assistance.'
        );
        setTimeout(function () {
          setContactModalFormError('');
        }, 5000);
      },
    });
  };

  let content = {
    English: {
      _footerPara:
        'Fill in the form below <br />to experience the flavors of <br />Lunsj På Døra for free',
      _footerL1: 'Your Name',
      _footerL2: 'Email',
      _footerL3: 'Phone Number',
      _footerL4: 'Postal Code',
      _footerBtn: 'TRY THE LUNSJ PÅ DØRA EXPERIENCE',
      _footerSubhead1: 'Our address',
      _footerBtn1: 'VIEW ON MAP',
      _footerSubhead2: 'Contact Us',
      _footerBtn2: 'SEND A MESSAGE',
      _footerPara1:
        'If you’d like to talk to our sales team immediately, contact',
      _msg1:
        'If the company you work at is already registered, go ahead and ask your admin to send you an invite to sign up and download the app!',
      _msg2:
        'Send us your details and we’ll reach out to your office admin at the earliest.',
      _p1: 'Your Name',
      _p2: 'Company Name',
      _p3: 'Company Address',
      _p4: 'Zip Code',
      _p5: 'Email',
      _p6: 'Contact Details',
      _p7: 'Your Contact Details',
      _p8: "Your Admin's Contact Details",
      _p9: 'Your First Name',
      _p10: 'Your Last Name',
      _p11: 'Your Message',
      _p12: 'Get In Touch',
      _p13: 'If you’d like to talk to our sales team immediately, contact',
      _P14: 'or',
      _cbtn: 'Continue',
    },
    Norwegian: {
      _footerPara:
        'Fyll ut punktene under for å oppleve smakene av <br />Lunsj på Døra - gratis!',
      _footerL1: 'Ditt navn',
      _footerL2: 'epost',
      _footerL3: 'telefonnummer',
      _footerL4: 'postnummer',
      _footerBtn: 'Prøv Lunsj På Døra Opplevelsen',
      _footerSubhead1: 'Vår adresse',
      _footerBtn1: 'SE I KART',
      _footerSubhead2: 'Kontakt oss',
      _footerBtn2: 'SEND EN MELDING',
      _footerPara1:
        'Hvis du vil snakke med noen fra vårt salgsteam umiddelbart, kontakt:',
      _msg1:
        'Hvis bedriften du jobber i allerede er registrert, er det bare og be administratoren din sende deg en invitasjon til å registrere deg og laste ned appen!',
      _msg2:
        'Send oss noen detaljer og vi vil ta kontakt med administrasjonen hos dere så raskt som mulig',
      _p1: 'Ditt navn',
      _p2: 'Bedriftens navn',
      _p3: 'Bedriftens adresse',
      _p4: 'Postnummer',
      _p5: 'Epostadresse',
      _p6: 'Andre opplysninger',
      _p7: 'Din kontaktinformasjon',
      _p8: 'Administratorens kontaktinformasjon',
      _p9: 'Fornavnet ditt',
      _p10: 'Ditt etternavn',
      _p11: 'Din beskjed',
      _p12: 'Ta kontakt',
      _p13: 'Hvis du vil snakke med salgsteamet vårt umiddelbart, ta kontakt',
      _p14: 'eller',
      _cbtn: 'Fortsette',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <div>
      {/* Get In Touch Modal Start */}
      <div
        className='modal fade reservation-popup'
        tabIndex='-1'
        role='dialog'
        id='contactUsModule'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa fa-times'></i>
              </button>
              <div className='row reservation'>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  <div className='reservation-form mb-30 bg-white text-center'>
                    <form
                      onSubmit={handleContactModalFormSubmit}
                      id='contactmodalForm'
                      className='contactUsForm mb-0'
                    >
                      <div className='row'>
                        <h6 style={{ marginBottom: 20 }}>{content._msg1}</h6>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <div className='text-left'>
                            {ContactModalFormerror ? (
                              <p
                                style={{ color: 'red' }}
                                className='error-message '
                              >
                                {ContactModalFormerror}
                              </p>
                            ) : null}
                            {ContactModalFormsuccess ? (
                              <p
                                style={{ color: 'green' }}
                                className='success-message '
                              >
                                {ContactModalFormsuccess}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-fname'
                            id='cmFname'
                            placeholder={content._p9}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-lname'
                            id='cmLname'
                            placeholder={content._p10}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='email'
                            className='form-control'
                            name='res-email'
                            id='cmEmail'
                            placeholder={content._p5}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-phone'
                            id='cmPhone'
                            placeholder={content._p6}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-company'
                            id='cmCompany'
                            placeholder={content._p2}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-zip'
                            id='cmZip'
                            placeholder={content._p4}
                            inputMode='numeric'
                            pattern='^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$'
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <textarea
                            className='form-control'
                            name='res-message'
                            id='cmMessage'
                            rows='2'
                            placeholder={content._p11}
                            required
                          ></textarea>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <button
                            type='submit'
                            name='submit1'
                            className='btn btn--primary btn--lg'
                          >
                            {content._p12}
                          </button>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <h6>{content._p13}</h6>
                          <div className='col-xs-12 col-sm-12 col-md-4'>
                            <p>
                              <span className='color-theme'>
                                Sebastian Fredstad
                              </span>
                              <br />
                              sebastian@conluo.no
                              <br />
                              tlf: 478 38 933
                            </p>
                          </div>
                          <div className='col-xs-12 col-sm-12 col-md-4'>
                            <h4>{content._p14}</h4>
                          </div>
                          <div className='col-xs-12 col-sm-12 col-md-4'>
                            <p>
                              <span className='color-theme'>
                                Caroline Borge
                              </span>
                              <br />
                              Caroline@conluo.no
                              <br />
                              tlf: 454 91 920
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Get In Touch Modal End */}

      {/* Register My Company Modal Start */}
      <div
        className='modal fade reservation-popup'
        tabIndex='-1'
        role='dialog'
        id='registerCompanyModule'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa fa-times'></i>
              </button>
              <div className='row reservation'>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  <div className='reservation-form mb-30 bg-white text-center'>
                    <form
                      onSubmit={handleCompanyFormSubmit}
                      id='companyForm'
                      className='registerCompanyForm mb-0'
                    >
                      <div className='row'>
                        <h3 style={{ fontSize: 20, marginBottom: 30 }}>
                          {content._msg1}
                        </h3>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <div className='text-left'>
                            {CompanyFormerror ? (
                              <p
                                style={{ color: 'red' }}
                                className='error-message '
                              >
                                {CompanyFormerror}
                              </p>
                            ) : null}
                            {CompanyFormsuccess ? (
                              <p
                                style={{ color: 'green' }}
                                className='success-message '
                              >
                                {CompanyFormsuccess}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-name'
                            id='companyRepName'
                            placeholder={content._p1}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-company'
                            id='companyName'
                            placeholder={content._p2}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-companyadd'
                            id='companyAdd'
                            placeholder={content._p3}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-zip'
                            id='companyZip'
                            placeholder={content._p4}
                            inputMode='numeric'
                            pattern='^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$'
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='email'
                            className='form-control'
                            name='res-email'
                            id='companyEmail'
                            placeholder={content._p5}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            name='res-phone'
                            id='companyDetails'
                            placeholder={content._p6}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <button
                            type='submit'
                            name='submit2'
                            className='btn btn--primary btn--lg'
                          >
                            {content._cbtn}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Register My Company Modal End */}

      {/* Send Info Company Modal Start */}
      <div
        className='modal fade reservation-popup'
        tabIndex='-1'
        role='dialog'
        id='sendInfoCompany'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa fa-times'></i>
              </button>
              <div className='row reservation'>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  <div className='reservation-form mb-30 bg-white text-center'>
                    <form
                      onSubmit={handleOfficeAdminFormSubmit}
                      id='officeadminForm'
                      className='sendInfoCompanyForm mb-0'
                    >
                      <div className='row'>
                        <h3 style={{ fontSize: 20, marginBottom: 30 }}>
                          {content._msg2}
                        </h3>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <div className='text-left'>
                            {officeadminFormerror ? (
                              <p
                                style={{ color: 'red' }}
                                className='error-message '
                              >
                                {officeadminFormerror}
                              </p>
                            ) : null}
                            {officeadminFormsuccess ? (
                              <p
                                style={{ color: 'green' }}
                                className='success-message '
                              >
                                {officeadminFormsuccess}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='send-name'
                            id='officeadminName'
                            placeholder={content._p1}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='send-company'
                            id='officeadminCompony'
                            placeholder={content._p2}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='send-phone'
                            id='officeadminPhone'
                            placeholder={content._p6}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='send-adminphone'
                            id='officeadminMob'
                            placeholder={content._p8}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='text'
                            className='form-control'
                            name='send-zip'
                            id='officeadminZip'
                            placeholder={content._p4}
                            inputMode='numeric'
                            pattern='^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$'
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                          <input
                            type='email'
                            className='form-control'
                            name='send-email'
                            id='officeadminEmail'
                            placeholder={content._p5}
                            required
                          />
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <button
                            type='submit'
                            name='submit3'
                            className='btn btn--primary btn--lg'
                          >
                            {content._cbtn}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Info Company Modal Start */}

      {/* Get a free Trial Modal Start */}
      <div
        className='modal fade reservation-popup'
        tabIndex='-1'
        role='dialog'
        id='reservationModule1'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa fa-times'></i>
              </button>
              <div className='row reservation'>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  <div className='reservation-form mb-30 bg-white text-center'>
                    <h6 style={{ marginBottom: 20 }}>{content._stickyBtn}</h6>
                    <form
                      onSubmit={handleTrialFormSubmit}
                      id='trialForm'
                      className='getFreeTrialForm mb-0'
                    >
                      <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <div className='text-left'>
                            {TrialFormerror ? (
                              <p
                                style={{ color: 'red' }}
                                className='error-message '
                              >
                                {TrialFormerror}
                              </p>
                            ) : null}
                            {TrialFormsuccess ? (
                              <p
                                style={{ color: 'green' }}
                                className='success-message '
                              >
                                {TrialFormsuccess}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            name='trialName'
                            id='trialName'
                            placeholder={content._footerL1}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='email'
                            className='form-control'
                            name='trialEmail'
                            id='trialEmail'
                            placeholder={content._footerL2}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            name='trialPhone'
                            id='trialPhone'
                            placeholder={content._footerL3}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <input
                            type='number'
                            className='form-control'
                            name='trialZip'
                            id='trialZip'
                            placeholder={content._footerL4}
                            required
                          />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <button
                            name='trylpdexp'
                            className='btn btn--primary btn--lg--padding'
                            style={{ width: '300 !important' }}
                          >
                            {content._footerBtn}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Get a free Trial Modal End */}
    </div>
  );
}

export default Modals;
