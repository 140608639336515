import { Box, Button, FormControl, Stack, TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseurl } from '../../utils/config';
import toast from 'react-hot-toast';

const headerSx = {
  color: '#8FB869',
  fontFamily: 'Raleway',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '23px',
  letterSpacing: '0em',
  textAlign: 'center',
};

const textFieldFormSx = {
  minWidth: { xs: '300px', sm: '400px' },
  backgroundColor: '#fff',
  borderRadius: '1px',
};
const textFieldFormSmallerSx = {
  minWidth: { xs: '220px', sm: '300px' },
  backgroundColor: '#fff',
  borderRadius: '1px',
};

const schema = yup.object({
  orgName: yup.string().trim().required('Organization Name is Required'),
  orgNumber: yup
    .string()
    .trim()
    .max(50)
    .required('Organization Number is Required'),
  referenceCode: yup.string().trim(),
  orgAdminName: yup
    .string()
    .trim()
    .required('Organization Admin Name is Required'),
  orgAdminPhone: yup
    .string()
    .trim()
    .required('Organization Admin Phone Number is Required'),
  /* .min(10, 'Enter the phone number like this: +47xxxxxxxx')
    .max(16, 'Enter the phone number like this: +47xxxxxxxx') */ orgAdminEmail:
    yup
      .string()
      .trim()
      .required('Organization Admin Email address is Required')
      .email('Invalid Email Address'),
  // otp: yup.string().required('OTP is Required'),
});

const CompanyOnboardingDetails = ({
  onClickSubmit,
  onClickBack,
  savedData,
}) => {
  const { t } = useTranslation();
  // const [otpView, showOtpView] = useState(
  //   savedData?.otpViewState !== undefined ? savedData.otpViewState : false
  // );
  const phoneNumberRef = useRef();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...savedData,
      orgAdminPhone:
        savedData?.orgAdminPhone === undefined
          ? '+47'
          : savedData.orgAdminPhone,
    },
  });

  // const handleOnClickValidate = () => {
  //   if (
  //     phoneNumberRef.current.value === undefined ||
  //     phoneNumberRef.current.value === null ||
  //     phoneNumberRef.current.value.length < 10
  //   ) {
  //     toast.error(t('Invalid Phone Number'));
  //     return;
  //   }

  //   const toastId = toast.loading(t('Sending OTP to user phone number...!'));
  //   axios
  //     .post(
  //       `${baseurl}/otp`,
  //       {
  //         phone: phoneNumberRef.current.value,
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       toast.dismiss(toastId);
  //       console.log(res);
  //       toast.success(t('OTP sent successfully'));
  //       showOtpView(true);
  //     })
  //     .catch((err) => {
  //       toast.dismiss(toastId);
  //       console.dir(err);
  //       toast.error(err.response.data.message);
  //     });
  // };

  const onSubmitCompanyOnBoardingDetails = async (data) => {
    console.log(data);
    onClickSubmit(data);
  };
  return (
    <Box
      sx={{
        width: {
          sx: '100%',
          md: '60%',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        flexDirection: 'column',
        mb: 4,
      }}
    >
      <Box
        sx={{
          fontFamily: 'Cormorant Garamond',
          fontSize: { xs: '28px', md: '45px' },
          fontWeight: { sx: 700, md: 600 },
          lineHeight: { sx: '33px', md: '54px' },
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#fff',
          mt: { sx: 4, md: 8 },
        }}
      >
        {t('Signup for Lunsj På Døra')}
      </Box>
      <form onSubmit={handleSubmit(onSubmitCompanyOnBoardingDetails)}>
        <Box
          mt={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={headerSx}>{t('Organisation number*')}</Box>
          <FormControl sx={textFieldFormSx}>
            <Controller
              control={control}
              defaultValue=''
              name='orgNumber'
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  type='text'
                  error={!!errors?.orgNumber}
                  helperText={t(errors?.orgNumber?.message)}
                />
              )}
            />
          </FormControl>
          <Box sx={headerSx}>{t('Organisation name*')}</Box>
          <FormControl sx={textFieldFormSx}>
            <Controller
              control={control}
              defaultValue=''
              name='orgName'
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  type='text'
                  error={!!errors?.orgName}
                  helperText={t(errors?.orgName?.message)}
                />
              )}
            />
          </FormControl>
          <Box sx={headerSx}>{t('Organisation admin / contact person*')}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='orgAdminName'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    error={!!errors?.orgAdminName}
                    helperText={t(errors?.orgAdminName?.message)}
                  />
                )}
              />
            </FormControl>
            <Box
              mt={1}
              sx={{
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#A5A5A5',
              }}
            >
              {t('Will be single point of contact for LPD')}
            </Box>
          </Box>

          <FormControl sx={textFieldFormSx}>
            <Controller
              control={control}
              defaultValue=''
              name='orgAdminEmail'
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  type='text'
                  placeholder={t('Email')}
                  error={!!errors?.orgAdminEmail}
                  helperText={t(errors?.orgAdminEmail?.message)}
                />
              )}
            />
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,

                justifyContent: 'space-between',
              }}
            >
              <FormControl sx={textFieldFormSx}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='orgAdminPhone'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputRef={phoneNumberRef}
                      variant='outlined'
                      placeholder='Phone'
                      type='text'
                      error={!!errors?.orgAdminPhone}
                      helperText={t(errors?.orgAdminPhone?.message)}
                    />
                  )}
                />
              </FormControl>
              {/* <Button
                variant='contained'
                sx={{
                  backgroundColor: '#8FB869',
                  color: '#FFFFFF',
                  paddingX: 1,
                  paddingY: 2,
                  flexGrow: 1,
                  fontFamily: 'Raleway',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  width: { xs: '60px', sm: '80px' },
                  '&:hover': {
                    backgroundColor: '#364564',
                    borderColor: '#364564',
                    color: '#ffffff',
                  },
                }}
                onClick={handleOnClickValidate}
              >
                {otpView ? t('Retry') : t('Verify')}
              </Button> */}
            </Box>

            {/* <Box
              mt={1}
              sx={{
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#A5A5A5',
                width: { xs: '300px', sm: '400px' },
              }}
            >
              {t('This number has to be validated with OTP code')}
            </Box> */}
          </Box>

          {/* {otpView ? (
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='otp'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    placeholder='OTP'
                    type='text'
                    error={!!errors?.otp}
                    helperText={t(errors?.otp?.message)}
                  />
                )}
              />
            </FormControl>
          ) : (
            ''
          )} */}
          <Box sx={headerSx}>{t('Reference code (Optional)')}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControl sx={textFieldFormSx}>
              <Controller
                control={control}
                defaultValue=''
                name='referenceCode'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    error={!!errors?.referenceCode}
                    helperText={t(errors?.referenceCode?.message)}
                  />
                )}
              />
            </FormControl>

            <Box
              mt={1}
              sx={{
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#A5A5A5',
                width: { xs: '300px', sm: '400px' },
              }}
            >
              {t(
                'Note: Nothing is ordered until we have been in contact with you.'
              )}
            </Box>
          </Box>

          <Stack
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              gap: {
                xs: 4,
                md: 3,
              },
            }}
            justifyContent={'center'}
          >
            <Button
              variant='outlined'
              onClick={(e) => {
                onClickBack({ ...getValues() /* , otpViewState: otpView  */ });
              }}
              sx={{
                paddingX: 16,
                paddingY: 2,
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'center',
                // '&:hover': {
                //   backgroundColor: '#364564',
                //   borderColor: '#364564',
                //   color: '#ffffff',
                // },
              }}
            >
              {t('Back')}
            </Button>

            <Button
              variant='contained'
              /* onClick={(e) => {
                console.log(otpView);
                //Check and handle otp error if phone number is verified or not
                if (!otpView) {
                  toast.error(
                    t(
                      'Please verify your mobile number before submitting form...!'
                    )
                  );
                }
              }} */
              sx={{
                backgroundColor: '#8FB869',
                color: '#FFFFFF',
                paddingX: 16,
                paddingY: 2,
                fontFamily: 'Raleway',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#364564',
                  borderColor: '#364564',
                  color: '#ffffff',
                },
              }}
              type='submit'
            >
              {t('Submit')}
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default CompanyOnboardingDetails;
