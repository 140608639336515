import React from "react";
import ReactGA from "react-ga4";


function GetTheApp(props) {

    const iosClick = () => {
 
        ReactGA.event({ category: 'Home', action: 'Click', label: 'Apple Store' });
        window.open('https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017', '_blank');

    };

    const andriodClick = () => {
 
        ReactGA.event({ category: 'Home', action: 'Click', label: 'Google Play Store' });

        window.open('https://play.google.com/store/apps/details?id=no.digiberry.lunchapp&gl=NO', '_blank');

    };

    let content = {
        English: {
            _section5Head1: "Get The App! ",
            _section5Head2: "TAKE CONTROL",
            _section5Para: "Download our easy-to-use app that allows you to view, select, cancel meals and do so much more at just the tap of a button!"
        },
        Norwegian: {
            _section5Head1: "Last ned Appen! ",
            _section5Head2: "TA KONTROLL",
            _section5Para: "Last ned vår lettforståelige App som gir deg mulighet til å se, velge, kansellere måltider, samt mye mer med kun et tastetrykk!"
        }
    };

    props.language === "Norwegian"
        ? (content = content.Norwegian)
        : (content = content.English);
    return (
        <section id="banner2" className="banner banner-2 text-center bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                        <div className="heading heading-1 text--center">
                            <p className="heading--subtitle">{content._section5Head1}</p>
                            <h2 className="heading--title">{content._section5Head2}</h2>
                            <div className="divider--shape-4"></div>
                            <p className="heading--desc">
                                {content._section5Para}
                            </p>
                        </div>

                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <div className="banner-img mt-20">
                            <a href="#" onClick={andriodClick}  rel="noreferrer noopener"><img src="assets/images/banners/gp.webp" alt="image" style={{ height: 60 }} /></a>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <div className="banner-img mt-20">
                            <a href="#" onClick={iosClick}   rel="noreferrer noopener"><img src="assets/images/banners/as.png" alt="image" style={{ height: 60 }} /></a>
                        </div>

                    </div>

                </div>

            </div>

        </section>

    );
}

export default GetTheApp;
