import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, TextField } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { forwardRef } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import classes from './HeaderFormSection.module.css';

const schema = yup.object({
  name: yup.string().trim().required('Navn er obligatorisk'),
  number: yup
    .string()
    .trim()
    .required('Vi trenger ditt mobilnummer')
    .min(
      7,
      'Ugyldig telefonnummer, telefonnummer bør være minimum 8 sifretelefonnummer bør være minimum 8 sifre'
    ),
  email: yup
    .string()
    .trim()
    .required('Vi trenger din epostadresse')
    .email('Ugyldig e-postadresse'),
  employer: yup.string().trim().required('Arbeidsgiver er påkrevd'),
});
const HeaderFormSection = forwardRef(({ changePinButtonVisibility }, ref) => {
  const location = useLocation();
  var currentPageURL = location.pathname + location.search;

  const fullUrl = window.location.href;
  const urlForApi = `${fullUrl.substring(
    0,
    fullUrl.lastIndexOf('/')
  )}/v1/feedback`;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const { t } = useTranslation();
  let toastId;
  const onSubmitCompanyOnBoardingDetails = async (data) => {
    console.log(data);
    toastId = toast.loading(t('Processing...!'));
    axios
      .post(
        urlForApi,
        {
          Name: data.name,
          Email: data.email,
          Phone: data.number,
          url: currentPageURL,
          formType: 'Free Trial Form',
          EmployerName: data.employer,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success(t('Information sent successfully'));
        navigate('/prov-gratis-sent', { replace: true });
        toast.dismiss(toastId);
      })
      .catch((err) => {
        toast.dismiss(toastId);
        console.error(err);
        if (err?.response?.message) toast.error(err?.response?.message);
      });
  };
  const { ref: btnRef, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    changePinButtonVisibility(!inView);
  }, [inView, changePinButtonVisibility]);

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        className={classes.img}
        sx={{
          display: { xs: 'none', lg: 'flex' },
        }}
      />
      <form onSubmit={handleSubmit(onSubmitCompanyOnBoardingDetails)}>
        <Box
          className={classes.form}
          sx={{
            padding: 3,
            marginTop: { xs: 0, lg: '80px' },
            width: {
              xs: '90%',
              lg: '60%',
            },
          }}
        >
          <Box className={classes.title}>Prøv vår lunsj gratis</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 4,
              gap: 2,
            }}
          >
            <FormControl
              sx={{
                backgroundColor: '#FFFFFF',
              }}
            >
              <Controller
                control={control}
                defaultValue=''
                name='name'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    placeholder='Navn'
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl
              sx={{
                backgroundColor: '#FFFFFF',
              }}
            >
              <Controller
                control={control}
                defaultValue=''
                name='number'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='number'
                    placeholder='Mobilnummer'
                    error={!!errors?.number}
                    helperText={errors?.number?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl
              sx={{
                backgroundColor: '#FFFFFF',
              }}
            >
              <Controller
                control={control}
                defaultValue=''
                name='email'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    placeholder='E-post'
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl
              sx={{
                backgroundColor: '#FFFFFF',
              }}
            >
              <Controller
                control={control}
                defaultValue=''
                name='employer'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    placeholder='Arbeidsgiver'
                    error={!!errors?.employer}
                    helperText={errors?.employer?.message}
                  />
                )}
              />
            </FormControl>
            <Button
              ref={btnRef}
              type='submit'
              variant='contained'
              sx={{
                backgroundColor: '#8FB869',
                borderRadius: '100px',
                padding: '12px 30px',
                ':hover': {
                  bgcolor: '#8fb869c1', // theme.palette.primary.main
                  color: 'white',
                },
              }}
            >
              Prøv Gratis
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
});

export default HeaderFormSection;
