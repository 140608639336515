import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, RouterProvider } from 'react-router-dom';
import './utils/i18n';
import { Toaster } from 'react-hot-toast';

import reportWebVitals from './reportWebVitals';
import { Suspense } from 'react';
import { router } from './routeProvider'; 

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Toaster
      position='top-right'
      toastOptions={{
        className: '',
        duration: 5000,
        style: {
          background: 'rgba(53, 68, 99, 1)',
          color: '#fff',
          fontWeight: 'bold',
        },
      }}
    />
    {/* <Router basename='/'>
      <App />
    </Router> */}
    <Suspense fallback='loading'>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
