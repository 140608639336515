import { Box } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const IconLabelComponent = ({ infoIcon, infoMessage }) => {
  return (
    <Box
      sx={{
        width: '260px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box
        sx={{
          height: 100,
          width: 100,
          backgroundColor: '#fff',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={infoIcon}
          alt={infoMessage}
          style={{
            width: '72px',
            height: '72px',
          }}
        />
      </Box>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        {t(infoMessage)}
      </Box>
    </Box>
  );
};

export default IconLabelComponent;
