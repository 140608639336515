import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import TypeAnimation from 'react-type-animation';

ReactGA.send({ hitType: 'pageview', page: '/home', title: 'home' });

function Slider(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: 'Home',
      action: 'Click',
      label: 'Register My Company',
    });

    navigate('/company-registration');
  };

  const handlesendInfoCompanyClick = () => {
    ReactGA.event({
      category: 'Home',
      action: 'Click',
      label: 'Send Info Company',
    });
  };

  let content = {
    English: {
      _bannerSubhead: 'Happiness Delivered to Your Desk!',
      _bannerBtn1: 'REGISTER MY COMPANY',
      _bannerBtn2: 'SEND INFO TO OFFICE ADMIN',
      _bannerHeading: 'Office Lunches Are For',
      _bannerSh: ['BRAINSTORMING.', 3000, 'BONDING.', 2000, 'BELONGING.', 2000],
    },
    Norwegian: {
      _bannerSubhead: 'Trivsel levert rett til pulten din!',
      _bannerBtn1: 'Registrer min bedrift',
      _bannerBtn2: 'Informer min sjef',
      _bannerHeading: 'Lunsj på jobben skaper',
      _bannerSh: [
        'Miljø',
        2000,
        'Idemyldring',
        3000,
        'Hygge',
        2000,
        'Teamfølelse',
        3000,
      ],
    },
  };
  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);

  function Typetext(props) {
    const lang = props.lang;
    if (lang == 'English') {
      return (
        <TypeAnimation
          className='txt-rotate'
          cursor={true}
          sequence={[
            'BRAINSTORMING.',
            3000,
            'BONDING.',
            2000,
            'BELONGING.',
            2000,
          ]}
          wrapper='span'
          repeat={Infinity}
        />
      );
    }
    return (
      <TypeAnimation
        className='txt-rotate'
        cursor={true}
        sequence={[
          'Miljø',
          2000,
          'Idemyldring',
          3000,
          'Hygge',
          2000,
          'Teamfølelse',
          3000,
        ]}
        wrapper='span'
        repeat={Infinity}
      />
    );
  }

  return (
    <section
      id='slider'
      className='slider slide-overlay-black bg-overlay-dark text--center'
    >
      <div className='slide--container'>
        <div className='video-container'>
          <video
            autoPlay
            muted
            loop
            id='myVideo'
            height='1280'
            style={{ marginTop: -200 }}
          >
            <source
              src='assets/images/sliders/slide-bg/video.mp4'
              type='video/mp4'
            />
          </video>
        </div>
        <div className='slide--item'>
          <div className='headerlogo header--panel-logo animate-pop-in'>
            <img src='assets/images/logo/Logo-Light-Main.png' width='100px' />
          </div>
          <h1
            className='slide--headline container-text-type animate-pop-in'
            style={{ paddingBottom: 0 }}
          >
            {content._bannerHeading} <br />
            <Typetext lang={props.language} />
          </h1>

          <div className='slide--bio text-center animate-pop-in col-xs-12 col-sm-12 col-md-12'>
            {content._bannerSubhead}
          </div>

          <div
            className='buttonAnim animate-pop-in'
            style={{ justifyContent: 'center' }}
          >
            <a
              className='btn btn--primary btn--lg animate-pop-in'
              data-target='#registerCompanyModule'
              style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
              onClick={handleClick}
            >
              {' '}
              {content._bannerBtn1}
            </a>{' '}
            <a
              className='btn btn--primary btn--lg animate-pop-in'
              data-toggle='modal'
              data-target='#sendInfoCompany'
              style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
              onClick={handlesendInfoCompanyClick}
            >
              {' '}
              {content._bannerBtn2}
            </a>
          </div>
        </div>
      </div>

      <div></div>
    </section>
  );
}

export default Slider;
