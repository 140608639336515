import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MobileStoreButton from 'react-mobile-store-button/lib/MobileStoreButton';
import ReactGA from 'react-ga4';

const iOSUrl =
  'https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017';
const androidUrl =
  'https://play.google.com/store/apps/details?id=no.digiberry.lunchapp';

const OnBoardingStoreInfo = (params) => {
  const { t } = useTranslation();
  const { message } = params;

  const iosClick = () => {
    ReactGA.event({
      category: 'Home',
      action: 'Click',
      label: 'Apple Store',
    });
    window.open(
      'https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017',
      '_blank'
    );
  };

  return (
    <Box mt={2}>
      <Box
        sx={{
          fontFamily: 'inter',
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '29px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#74A338',
        }}
      >
        {message}
      </Box>
      <Box
        mt={10}
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19px',
          letterSpacing: '0em',
          textAlign: 'center',
        }}
      >
        {t('Please download the app from app store or playstore')}
      </Box>
      <Box
        mt={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={iosClick}
          rel='noreferrer noopener'
          sx={{
            cursor: 'pointer',
          }}
        >
          <img
            src='assets/images/banners/as.webp'
            alt='IOS Store link'
            style={{ height: 44 }}
          />
        </Box>
        <MobileStoreButton
          height='62px'
          width='161px'
          store='android'
          url={androidUrl}
          linkProps={{ title: 'Android PlayStore Button' }}
        />
      </Box>
    </Box>
  );
};

export default OnBoardingStoreInfo;
