import React from 'react';

function MenuMeaty(props) {
  let content = {
    English: {
      _menu1D1Head: 'Pulled Pork Open Sandwich',
      _menu1D1Subhead:
        'Fresh Sourdough topped with juicy pulled pork slathered in barbeque sauce and accompanied with crunchy coleslaw.',
      _menu1D1Para:
        '(Allergens: Gluten- hvete, rug, bygg, sesam, soya, sennep, egg, selleri)',
      _menu1D2Head: 'Spiced Sweet Potato Chorizo Salad Bowl',
      _menu1D2Subhead:
        'Quinoa topped with spicy chorizo and seasoned sweet potato. Served with cilantro vinaigrette and house-baked bread.',
      _menu1D2Para: '(Soya, sulfitt, gluten- hvete, rug, bygg)',
      _menu1D3Head: 'Slow Cooked Indian Dal with Fluffy Naan Bread',
      _menu1D3Subhead:
        'Slow-cooked lentil stew, lightly spiced, with a coconut milk base, served with fresh sour cream and home-baked fluffy naan bread.',
      _menu1D3Para: '(Allergens: Gluten- hvete, laktose- melk)',
      _menu1D4Head: 'Asian Noodle Salad with Beef Strips',
      _menu1D4Subhead:
        'Spicy noodle salad topped with soy marinated beef and crunchy cashews.',
      _menu1D4Para:
        '(Allergens: Egg, gluten- hvete, soya, nøtter- cashew, bløtdyr)',
      _menu1D5Head: 'Chicken Thighs with Honey Glazed Vegetables',
      _menu1D5Subhead:
        'Succulent grilled chicken served with fried potatoes, honey glazed vegetables, and drizzled with a tangy tomato sauce.',
      _menu1D5Para: '(Allergens: Selleri, sulfitt)',
      _menu1D6Head: 'Juicy Chicken Wrap',
      _menu1D6Subhead:
        'Tortilla stuffed with crunchy farm-fresh vegetables, seasoned sweet potato, and a juicy chicken filet.',
      _menu1D6Para: '(Allergens: gluten- hvete)',
      _menu1D7Head:
        'Crunchy Pork Strip Salad Bowl with Tahini & Signature House-Baked Bread',
      _menu1D7Subhead:
        'Barley topped with flavourful sweet potatoes, soft-baked beets and pork strips. Served with tahini dressing and signature house-baked bread.',
      _menu1D7Para: '(Allergens: Sesam, gluten- bygg, hvete, rug)',
      _menu1D8Head: 'Spiced Hungarian Chuck Goulash Casserole',
      _menu1D8Subhead:
        'Classic Hungarian paprika and tomato meat casserole served on a bed of warm rice and creamy yogurt.',
      _menu1D8Para: '(Allergens: laktose- melk, selleri)',
      _menu1D9Head: 'Chicken and Feta Salad',
      _menu1D9Subhead:
        'Chicken filet, baked sweet potato and feta served on a bed of farm fresh crunchy salad leaves.',
      _menu1D9Para: '(Allergens: gluten- hvete, rug, bygg, laktose- melk)',
      _menu1D10Head: 'Braised Pork Neck with Potato Wedges',
      _menu1D10Subhead:
        'Slow braised pork neck with root vegetables fricassee and golden potato wedges.',
      _menu1D10Para: '(Allergens: Selleri, laktose- melk)',
      _menu1D11Head: 'Tuna Open Sandwich with House-Baked Sourdough',
      _menu1D11Subhead:
        'House-baked sourdough topped with fresh tuna salad and crunchy red onions.',
      _menu1D12Head: 'Pulled Pork Open Sandwich',
      _menu1D12Subhead:
        'Fresh Sourdough topped with juicy pulled pork slathered in barbeque sauce and accompanied with crunchy coleslaw.',
      _menu1D12Para:
        '(Allergens: Gluten- hvete, rug, bygg, sesam, soya, sennep, egg, selleri)',
      _menu1D13Head:
        'Protein Loaded Chicken & Chickpeas Salad with House-Baked Bread',
      _menu1D13Subhead:
        'Chicken, seasoned chickpeas, avocado, sprouts on a bed of fresh salad leaves topped with ranch dressing, served with house-baked bread.',
      _menu1D13Para: '(Allergens: Laktose- melk, Gluten- hvete, bygg, rug)',
      _menu1D14Head: 'Signature Sweet n Sour Pork',
      _menu1D14Subhead:
        'Succulent pork neck braised in sweet n sour sauce topped with juicy pineapples, crunchy sugar snaps. Served with warm rice.',
      _menu1D14Para: '(Allergens: Soya, Sulfitt)',
      _menu1D15Head: 'Broccoli and Crispy Bacon Salad with House-Baked Bread',
      _menu1D15Subhead:
        'Creamy broccoli, crispy bacon, raisins, sunflower and pumpkin seeds served with farm fresh salad leaves and house-baked bread.',
      _menu1D15Para: '(Allergens: Laktose- melk, Gluten- hvete, rug, bygg)',
      _menu1D16Head: 'Lasagna',
      _menu1D16Subhead:
        'Classic lasagna in tangy tomato sauce served with a side of farm-fresh salad.',
      _menu1D16Para: '(Allergens: Gluten- hvete, Laktose- melk, Egg, Selleri)',
      _menu1D17Head: 'Ham and Cheese Whole Grain Baguette',
      _menu1D17Subhead:
        'House-baked whole grain baguette with classic ham and cheese.',
      _menu1D17Para: '(Allergens: gluten- hvete, laktose- melk)',
      _menu1D18Head: 'Chicken and Feta Salad with House Baked Bread',
      _menu1D18Subhead:
        'Farm fresh salad with chicken, feta cheese and barley served with house-baked bread.',
      _menu1D18Para: '(Allergens: laktose- melk, gluten- bygg, hvete, rug)',
    },
    Norwegian: {
      _menu1D1Head: 'Pulled Pork Open Sandwich',
      _menu1D1Subhead:
        'Frisk surdeig toppet med saftig pulled pork ristet i barbequesaus og akkompagnert med crunchy coleslaw.',
      _menu1D1Para:
        '(Allergener: Gluten-hvete, rug, bygg, sesam, soya, sennep, egg, selleri)',
      _menu1D2Head: 'Krydret søtpotet chorizosalatskål',
      _menu1D2Subhead:
        'Quinoa toppet med krydret chorizo ​​og krydret søtpotet. Serveres med koriandervinaigrette og husbakt brød.',
      _menu1D2Para: '(Soya, sulfitt, gluten-hvete, rug, bygg)',
      _menu1D3Head: 'Saktekokt indisk Dal med luftig Naan-brød',
      _menu1D3Subhead:
        'Saktekokt linsestuing, lett krydret, med kokosmelkbunn, servert med fersk rømme og hjemmebakt luftig naanbrød.',
      _menu1D3Para: '(Allergener: Gluten-hvete, laktose-melk)',
      _menu1D4Head: 'Asiatisk nudelsalat med biffstrimler',
      _menu1D4Subhead:
        'Krydret nudelsalat toppet med soyamarinert biff og crunchy cashewnøtter.',
      _menu1D4Para:
        '(Allergener: Egg, gluten- hvete, soya, nøtter- cashew, bløtdyr)',
      _menu1D5Head: 'Kyllinglår med honningglaserte grønnsaker',
      _menu1D5Subhead:
        'Sukkulent grillet kylling servert med stekte poteter, honningglaserte grønnsaker og dryppet med en syrlig tomatsaus.',
      _menu1D5Para: '(Allergener: Selleri, sulfitt)',
      _menu1D6Head: 'Juicy Chicken Wrap',
      _menu1D6Subhead:
        'Tortilla fylt med sprø gårdsfriske grønnsaker, krydret søtpotet og en saftig kyllingfilet.',
      _menu1D6Para: '(Allergener: gluten-hvete)',
      _menu1D7Head:
        'Crunchy svinekjøttstrips salatbolle med tahini og hjemmebakt signaturbrød',
      _menu1D7Subhead:
        'Bygg toppet med smaksrike søtpoteter, bløtbakte rødbeter og svinekjøttstrimler. Serveres med tahinidressing og signaturhusbakt brød.',
      _menu1D7Para: '(Allergener: sesam, gluten-bygg, hvete, rug)',
      _menu1D8Head: 'Krydret ungarsk Chuck Gulasjgryte',
      _menu1D8Subhead:
        'Klassisk ungarsk paprika- og tomatkjøttgryte servert på en seng med varm ris og kremaktig yoghurt.',
      _menu1D8Para: '(Allergener: laktose-melk, selleri)',
      _menu1D9Head: 'Kylling og feta salat',
      _menu1D9Subhead:
        'Kyllingfilet, bakt søtpotet og feta servert på en seng med friske sprø salatblader fra gården.',
      _menu1D9Para: '(Allergener: gluten-hvete, rug, bygg, laktose-melk)',
      _menu1D10Head: 'Braisert svinenakke med potetskiver',
      _menu1D10Subhead:
        'Saktebrisert svinenakke med rotgrønnsaksfrikassé og gyldne potetbåter.',
      _menu1D10Para: '(Allergener: Selleri, laktosemelk)',
      _menu1D11Head: 'Tuna Open Sandwich med husbakt surdeig',
      _menu1D11Subhead:
        'Husbakt surdeig toppet med frisk tunfisksalat og sprø rødløk.',
      _menu1D12Head: 'Pulled Pork Open Sandwich',
      _menu1D12Subhead:
        'Frisk surdeig toppet med saftig pulled pork ristet i barbequesaus og akkompagnert med crunchy coleslaw.',
      _menu1D12Para:
        '(Allergener: Gluten-hvete, rug, bygg, sesam, soya, sennep, egg, selleri)',
      _menu1D13Head: 'Proteinfylt kylling- og kikertsalat med hjemmebakt brød',
      _menu1D13Subhead:
        'Kylling, krydrede kikerter, avokado, spirer på en seng av friske salatblader toppet med ranchdressing, servert med husbakt brød.',
      _menu1D13Para: '(Allergener: Laktose-melk, Gluten-hvete, bygg, rug)',
      _menu1D14Head: 'Signatur Sweet n Sour Pork',
      _menu1D14Subhead:
        'Sukkulent svinenakke braisert i søt og sur saus toppet med saftige ananas, knasende sukkersnaps. Serveres med varm ris.',
      _menu1D14Para: '(Allergener: Soya, Sulfitt)',
      _menu1D15Head: 'Brokkoli og sprø baconsalat med husbakt brød',
      _menu1D15Subhead:
        'Kremet brokkoli, sprøstekt bacon, rosiner, solsikke- og gresskarkjerner servert med friske salatblader og husbakt brød.',
      _menu1D15Para: '(Allergener: Laktose-melk, Gluten-hvete, rug, bygg)',
      _menu1D16Head: 'Lasagne',
      _menu1D16Subhead:
        'Klassisk lasagne i syrlig tomatsaus servert med en side av gårdsfrisk salat.',
      _menu1D16Para: '(Allergener: Gluten-hvete, Laktose-melk, Egg, Selleri)',
      _menu1D17Head: 'Fullkornsbaguette med skinke og ost',
      _menu1D17Subhead: 'Husbakt fullkornsbaguette med klassisk skinke og ost.',
      _menu1D17Para: '(Allergener: gluten-hvete, laktose-melk)',
      _menu1D18Head: 'Kylling og feta salat med husbakt brød',
      _menu1D18Subhead:
        'Farm fresh salat med kylling, fetaost og bygg servert med husbakt brød.',
      _menu1D18Para: '(Allergener: laktose-melk, gluten- bygg, hvete, rug)',
    },
  };

  props.language === 'Norwegian'
    ? (content = content.Norwegian)
    : (content = content.English);
  return (
    <section id='menu1' className='menu menu-1 bg-white'>
      <div className='container'>
        <div className='row'>
          <center>
            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D1Head}</h4>

                <p className='dish--desc'>{content._menu1D1Subhead}.</p>
                <p className='color-theme'>{content._menu1D1Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D2Head}</h4>

                <p className='dish--desc'>{content._menu1D2Subhead}.</p>
                <p className='color-theme'>{content._menu1D2Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D3Head}</h4>

                <p className='dish--desc'>{content._menu1D3Subhead}.</p>
                <p className='color-theme'>{content._menu1D3Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D4Head}</h4>

                <p className='dish--desc'>{content._menu1D4Subhead}.</p>
                <p className='color-theme'>{content._menu1D4Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D5Head}</h4>

                <p className='dish--desc'>{content._menu1D5Subhead}.</p>
                <p className='color-theme'>{content._menu1D5Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D6Head}</h4>

                <p className='dish--desc'>{content._menu1D6Subhead}.</p>
                <p className='color-theme'>{content._menu1D6Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D7Head}</h4>

                <p className='dish--desc'>{content._menu1D7Subhead}.</p>
                <p className='color-theme'>{content._menu1D7Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D8Head}</h4>

                <p className='dish--desc'>{content._menu1D8Subhead}.</p>
                <p className='color-theme'>{content._menu1D8Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D9Head}</h4>

                <p className='dish--desc'>{content._menu1D9Subhead}.</p>
                <p className='color-theme'>{content._menu1D9Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D10Head}</h4>

                <p className='dish--desc'>{content._menu1D10Subhead}.</p>
                <p className='color-theme'>{content._menu1D10Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D11Head}</h4>

                <p className='dish--desc'>{content._menu1D11Subhead}.</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D12Head}</h4>

                <p className='dish--desc'>{content._menu1D12Subhead}.</p>
                <p className='color-theme'>{content._menu1D12Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D13Head}</h4>

                <p className='dish--desc'>{content._menu1D13Subhead}.</p>
                <p className='color-theme'>{content._menu1D13Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D14Head}</h4>

                <p className='dish--desc'>{content._menu1D14Subhead}.</p>
                <p className='color-theme'>{content._menu1D14Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D15Head}</h4>

                <p className='dish--desc'>{content._menu1D15Subhead}.</p>
                <p className='color-theme'>{content._menu1D15Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D16Head}</h4>

                <p className='dish--desc'>{content._menu1D16Subhead}.</p>
                <p className='color-theme'>{content._menu1D16Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D17Head}</h4>

                <p className='dish--desc'>{content._menu1D17Subhead}.</p>
                <p className='color-theme'>{content._menu1D17Para}</p>
              </div>
            </div>

            <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='dish-panel'>
                <h4>{content._menu1D18Head}</h4>

                <p className='dish--desc'>{content._menu1D18Subhead}.</p>
                <p className='color-theme'>{content._menu1D18Para}</p>
              </div>
            </div>
          </center>
        </div>
      </div>
    </section>
  );
}

export default MenuMeaty;
