import { Box } from '@mui/material';
import React from 'react';
import classes from './ManagementView.module.css';
const ManagementView = ({ name, desig, message, img }) => {
  return (
    <Box
      sx={{
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        // minWidth: '580px',
        // maxWidth: '600px',
        flex: '1 1 0',
        backgroundColor: '#ffffff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '18px',
        }}
      >
        <img
          src={img}
          alt='food'
          style={{
            width: '88px',
            height: '88px',
            objectFit: 'fill',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box className={classes.name}>{name}</Box>
          <Box className={classes.desig}>{desig}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box className={classes.comment}>{message}</Box>
      </Box>
    </Box>
  );
};

export default ManagementView;
