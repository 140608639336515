import { Box } from '@mui/material';
import React from 'react';
import { ImQuotesLeft } from 'react-icons/im';
import classes from './ReviewCard.module.css';
const ReviewCard = ({ comment, img, name, company, isGreen }) => {
  return (
    <Box
      sx={{
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        // minWidth: '430px',
        // maxWidth: '500px',
        flex: '1 1 0',
        height: '100%',
        backgroundColor: isGreen ? '#E9F1E1' : '#FFFFFF',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
        }}
      >
        <ImQuotesLeft fontSize='40' style={{ color: '#8FB869' }} />
        <Box className={classes.comment}>{comment}</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            height: 100,
            width: 100,
            backgroundColor: '#fff',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={img}
            alt='food'
            style={{
              width: '88px',
              height: '88px',
              objectFit: 'fill',
            }}
          />
        </Box>
        <Box
          className={classes.name}
          sx={{
            mt: 2,
          }}
        >
          {name}
        </Box>
        <Box className={classes.desig}>{company}</Box>
      </Box>
    </Box>
  );
};

export default ReviewCard;
