import moment from 'moment';
export const getFormattedDate = (date) => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return year + '-' + month + '-' + day;
};

export const dateFormatToDisplay = 'DD MMM YYYY';
export const dateFormatForServer = 'YYYY-MM-DD';

export const twoDaysFromCurrentDate = (date) => {
  const { nextBusinessDay, nextNextBusinessDay } = getNextBusinessDay();

  return (
    date.format(dateFormatToDisplay) === moment().format(dateFormatToDisplay) ||
    date.format(dateFormatToDisplay) === nextBusinessDay ||
    date.format(dateFormatToDisplay) === nextNextBusinessDay ||
    date.day() === 6 ||
    date.day() === 0
  );
};

export const getNextBusinessDay = () => {
  const currentDay = moment().day();
  let nextBusinessDay = moment().add(1, 'day').format(dateFormatToDisplay);
  let nextNextBusinessDay = moment().add(2, 'day').format(dateFormatToDisplay);
  let nextBusinessDaySelection = moment()
    .add(3, 'day')
    .format(dateFormatToDisplay);
  switch (currentDay) {
    case 3:
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    case 4: {
      nextNextBusinessDay = moment().add(4, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    case 5: {
      nextBusinessDay = moment().add(3, 'day').format(dateFormatToDisplay);
      nextNextBusinessDay = moment().add(4, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    case 6: {
      nextBusinessDay = moment().add(2, 'day').format(dateFormatToDisplay);
      nextNextBusinessDay = moment().add(3, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(4, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    default: {
    }
  }

  return { nextBusinessDay, nextNextBusinessDay, nextBusinessDaySelection };
};
