import { Box } from '@mui/material';
import React from 'react';
import classes from './FooterSocialMediaSection.module.css';

const FooterSocialMediaSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box className={classes.title}>Følg oss på sosiale medier</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <img
          src='assets/images/social/world-wide-web.svg'
          width={'28px'}
          height={'28px'}
          alt='world-wide-web'
          className={classes.icon}
          onClick={() => {
            window.open('https://lunsjpadora.no');
          }}
        />
        <img
          src='assets/images/social/facebook.svg'
          width={'28px'}
          height={'28px'}
          alt='facebook'
          onClick={() => {
            window.open('https://www.facebook.com/lunsjpadora');
          }}
          className={classes.icon}
        />
        <img
          src='assets/images/social/instagram.svg'
          width={'28px'}
          height={'28px'}
          alt='instagram'
          className={classes.icon}
          onClick={() => {
            window.open('https://www.instagram.com/lunsjpadora');
          }}
        />
        <img
          src='assets/images/social/linkedin.svg'
          width={'28px'}
          height={'28px'}
          alt='linkedin'
          className={classes.icon}
          onClick={() => {
            window.open(
              'https://www.linkedin.com/company/lunsj-p%C3%A5-d%C3%B8ra/'
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default FooterSocialMediaSection;
