import { createBrowserRouter } from 'react-router-dom';
import App, { Home, Menudaily, Menumeaty, Menuveg } from './App';
import OnBoardingPage from './pages/app-user-registration/OnBoardingPage';
import CompanyOnboardingPage from './pages/company-onboarding/CompanyOnboardingPage';
import FormSubmittedInfoScreen from './pages/new-onboarding-screen/FormSubmittedInfoScreen';
import NewOnBoardingScreen from './pages/new-onboarding-screen/NewOnBoardingScreen';
import Test from './Test';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        index: '/',
        element: <Home />,
      },
      {
        path: '/company-registration',
        element: <CompanyOnboardingPage />,
      },
      {
        path: 'MeatyGoodnessMenu',
        element: <Menumeaty />,
      },
      { path: 'VegetarianFressnessMenu', element: <Menuveg /> },
      {
        path: 'DailyFairMenu',
        element: <Menudaily />,
      },
      {
        path: 'app-user-registration',
        element: <OnBoardingPage />,
      },
    ],
  },
  {
    path: '/test',
    element: <Test />,
  },
  {
    path: '/provgratis',
    element: <NewOnBoardingScreen />,
  },
  {
    path: '/prov-gratis-sent',
    element: <FormSubmittedInfoScreen />,
  },
]);
