import React,{ useEffect } from "react";
import $ from "jquery";
import HTMLReactParser from "html-react-parser";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Testi(props) {

    let content = {
        English: {
            _TestiHead1: "Food is Love",
            _TestiHead2: "Here’s Some Love For Our Food!",
            _Testi1: "We are very happy with the food. It is very good quality of what we get. The food is perceived as healthy and varied and there is a good balance between the green, the carbohydrates and getting full.",
            _Testi2: "The quality of the food and that it is perceived as healthy.In terms of portions, it is also very good. Its not too little food.",
            _Testi3: "It was hard to fly out to buy food for everyone every day. We wanted good food right at the door. This suits us perfectly!",
            _Testi4: "Between 10 and 1030, the Conluo car comes here every day. The finished food is placed on the dining table by the driver. It fits perfectly. If it is hot food, then it is also still piping hot. Thats great",
            _section5Para1: "Ørn Software AS is a leading Nordic SaaS supplier in both industrial maintenance and FDV - property management, operation and maintenance <a target='_blank' href='https://www.ornsoftware.com/'>https://www.ornsoftware.com</a>"
        },
        Norwegian: {
            _TestiHead1: "Mat er kjærlighet",
            _TestiHead2: "Kjærlighetserklæringer til vår mat!",
            _Testi1: "Vi er kjempefornøyde med maten. Det er veldig god kvalitet på det vi får. Maten oppleves som sunn og variert og det er liksom en god balanse mellom det grønne, karbohydratene og det å bli mett.",
            _Testi2: "Kvaliteten på maten og at det oppleves som sunt.Porsjonsmessig er det også veldig bra. Det er ikke for lite mat.",
            _Testi3: "Det var tungt å fly ut å kjøpe mat til alle sammen hver dag. Vi ville gjerne ha bra mat rett på døra. Dette passer oss helt suverent",
            _Testi4: "Mellom klokken 10 og 1030 kommer Conluo-bilen hit hver dag. Den ferdige maten blir plassert på spisebordet av sjåføren. Det passer helt suverent. Hvis det er varmmat, så er den også fortsatt rykende varm. Det er helt supert",
            _section5Para1: "Ørn Software AS er en ledende nordisk SaaS-leverandør innen både industrielt vedlikehold og FDV – forvaltning, drift og vedlikehold av eiendom <a target='_blank' href='https://www.ornsoftware.com/'>https://www.ornsoftware.com</a>"
        }
      };

      props.language === "Norwegian"
    ? (content = content.Norwegian)
    : (content = content.English);
    useEffect( () => {
        
        var bgSrc = $(".test").children("img").attr("src");
  
        var bgUrl = "url(" + bgSrc + ")";
        $(".test").parent().css("backgroundImage", bgUrl);
        $(".test").parent().addClass("bg-section");
        $(".test").remove();
      
    },[]);
  return (
    <section id="testimonial4"  className="testimonial testimonial-4 bg-overlay bg-overlay-dark bg-parallax text-center">
    <div id="bgSection" className="bg-section test">
        <img src="assets/images/background/6.webp" alt="Background" /> 
    </div>
    <div className="container">
        <div className="divider--shape-1up"></div>

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                <div className="heading heading-1 text--center mb-50">
                    <p className="heading--subtitle">{content._TestiHead1 }</p>
                    <h2 className="heading--title text-white">
                        {content._TestiHead2 }
                    </h2>
                    <div className="divider--shape-4"></div>
                </div>
                <OwlCarousel autoplay="true" loop="true" items="1" center="true" id="testimonial-wide4" className="carousel carousel-white"    dots="false" space="0"  speed="800">
                    <div className="testimonial-panel item">
                        <div className="testimonial--rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="testimonial--body">
                            <p className="color-white">
                                “{content._Testi3 }”
                            </p>
                        </div>
                    </div>
                    <div className="testimonial-panel item">
                        <div className="testimonial--rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="testimonial--body">
                            <p className="color-white">
                                “{content._Testi4 }”
                            </p>
                        </div>
                    </div>
                    <div className="testimonial-panel item">
                        <div className="testimonial--rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="testimonial--body">
                            <p className="color-white">
                                “{content._Testi1 }”
                            </p>
                        </div>
                    </div>
                    <div className="testimonial-panel item">
                        <div className="testimonial--rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="testimonial--body">
                            <p className="color-white">
                                “{content._Testi2 }”
                            </p>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
                <div className="heading heading-1 text--center mt-50">
                    <p className="heading--subtitle">Ørn Software</p>
                    <div className="divider--shape-4"></div>
                    <p className="color-white">
                        {HTMLReactParser(content._section5Para1) }
                       
                    </p>
                </div>
            </div>
        </div>
        <div className="divider--shape-1down"></div>
    </div>
</section>
   
  );
}

export default Testi;
